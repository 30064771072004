.loginpage {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loginpage::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  width: 130%;
  height: 100%;
  background: linear-gradient(
      to right,
      rgba(6, 117, 162, 0.8),
      rgba(76, 12, 38, 0.8)
    ),
    url("../../img/heroimg.jpg") center top no-repeat;
  background-size: cover;
  z-index: 0;
  border-radius: 0 0 0% 0%;
  transform: translateX(-50%) rotate(0deg);
}

.loginbox {
  width: 70%;
  background-color: white;
  position: absolute;
  z-index: 9;
  display: grid;
  grid-template-columns: 60% 40%;
  overflow: hidden;
  border-radius: 5px;
}

.login-reg p {
  display: flex;
  justify-content: flex-end;
  padding: 0px 10px;
  font-family: Poppins-Regular;
  font-size: 14px;
}
.login-reg span {
  font-family: Poppins-SemiBold;
  color: #005e82;
  padding: 0px 5px;
}
.login-reg text {
  color: #eb347d;
}
.login-space {
  padding: 0px 25px;
  padding-bottom: 40px;
}
.login-title h2 {
  font-family: Poppins-Regular;
  color: #005e82;
  text-align: center;
}
.login-textfield{
  position: relative;
}
.login-textfield-1 label,
.login-textfield-2 label {
  padding: 10px 0px;
  font-size: 13px;
  font-family: Poppins-Regular;
}
.login-forgotPassword {
  font-size: 13px;
  text-align: right;
}
.login-forgotPassword span {
  color: #005e82;
  font-family: Poppins-Regular;
  cursor: pointer;
}
.loginbtn {
  margin-top: 20px;
}
.login-or {
  padding: 15px 0px;
}
.login-or .login-or-style {
  font-size: 14px;
  font-family: Poppins-Regular;
  color: rgb(119, 118, 118);
  font-weight: 400;
}
.btn-color-for-signup {
  background-color: #eb347d !important;
}
.loginside-img {
  position: relative;
  overflow: hidden;
}
.loginside-img img {
  width: 100%;
  height: 100%;
}
.overlay-login {
  position: absolute;
  width: 100%;
  height: 30%;
  bottom: 0px;
  z-index: 9999;
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
}
.overlay-login-1 {
  position: absolute;
  width: 100%;
  height: 40%;
  top: 0px;
  z-index: 9999;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), transparent);
}
.overlay-text {
  width: 55%;
  margin-left: -32px;
  overflow: hidden;
  border-radius: 10px;
}
.overlay-login .overlay-text p {
  width: 65%;
  font-family: Poppins-Regular;
  font-size: 13px;
}

.overlay-login .overlay-text p span {
  font-weight: bold;
  font-family: Poppins-Medium;
  background-color: white;
  color: #005e82;
  padding: 0px 5px;
}
.overlay-login .overlay-text p span text {
  color: #eb347d;
}

.overlay-login .overlay-text {
  width: 100%;
  background-color: transparent;
  color: white;
  height: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .loginbox {
    display: grid;
    grid-template-columns: 100%;
    width: 90%;
  }
  .loginside-img {
    display: none;
  }
}
@media only screen and (max-width: 894px) and (min-width: 600px) {
  .loginbox {
    display: grid;
    grid-template-columns: 100%;
    width: 50%;
  }
  .loginside-img {
    display: none;
  }
}

/* forgotpassword page */
.forgotpasswordpage {
  height: 100vh;
  background-color: rgba(6, 117, 162, 0.1);
  position: relative;
}
.forgotpasswordimg {
  position: absolute;
  color: white;
  z-index: 9;
  top: 8px;
  left: 16px;
  width: 9%;
  overflow: hidden;
  border-radius: 5px;
  background-color: white;
}
.forgotpasswordimg img {
  width: 100%;
}

.forgotpassword-input-box {
  width: 35%;
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.1);
  position: absolute;
  background-color: white;
  border-radius: 5px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.forgotpasswordpage-logo-top {
  width: 100%;
  height: 45vh;
  overflow: hidden;
  position: relative;
}

.forgotpasswordpage-logo-top::after {
  background: linear-gradient(
      90deg,
      rgba(6, 117, 162, 0.8),
      rgba(76, 12, 38, 0.8)
    ),
    url(/src/img/heroimg.jpg) top no-repeat;
  background-size: cover;
  content: "";
  height: 100%;
  left: 50%;
  position: absolute;
  top: 0;
  -webkit-transform: translateX(-50%) rotate(0deg);
  transform: translateX(-50%) rotate(0deg);
  width: 130%;
  z-index: 0;
}
.forgotpassword-contect,
.back-to-loginpage {
  text-align: center;
  font-family: Poppins-Regular;
  font-size: 13px;
  padding: 0px 20px;
  color: #656567;
}
.space-forgotpassword {
  padding: 0px 50px;
}

.back-to-loginpage {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.back-to-landing {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Poppins-Regular;
  font-size: 13px;
  padding: 0px 20px;
  color: #656567;
  top: 97%;
  left: 50%;
  transform: translate(-50%,100%);
}

/* top: 90%;
  left: 50%;
  transform: translate(-50%,100%); */
.arrow-hide-otp-page {
}
#arrow-hide-otp-page::-webkit-inner-spin-button,
#arrow-hide-otp-page::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
}

#arrow-hide-otp-page {
  -moz-appearance: textfield !important;
}

/* .forgotpassword-input-box .login-space{

} */

@media only screen and (max-width: 600px) {
  .forgotpassword-input-box {
    width: 90%;
    top: 50%;
  }
  .forgotpasswordimg {
    width: 30%;
  }
}

@media only screen and (max-width: 894px) and (min-width: 600px) {
  .forgotpassword-input-box {
    width: 50%;
  }
  .forgotpasswordimg {
    width: 25%;
  }
}

.otp-page {
  text-align: center;
  font-family: Poppins-Regular;
  font-size: 12px;
  padding: 0px 20px;
  color: #656567;
}
