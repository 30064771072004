.file-card1 {
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 3px dashed #cbd5e0;
  background-color: rgba(166, 199, 212, 0.1);
  min-width: 100px;
  min-height: 230px;
}

.file-card1 .file-inputs1 {
  position: relative;
  margin-bottom: 1.5em;
}

.file-card1 .file-inputs1 input {
  position: relative;
  text-align: right;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
  height: 46px;
  max-width: 200px;
}

.main1 {
  font-weight: bold;
  margin-bottom: 0.4em;
}

.info1 {
  font-size: 0.8rem;
}

.fileuploadcenter1 {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

}



@media only screen and (min-width: 250px) {
  .resumeUpload.MuiButton-root {
    padding: 8px;
    font-size: 8px;
  }
}

@media only screen and (min-width: 300px) {
  .resumeUpload.MuiButton-root {
    padding: 5px;
    font-size: 12px;
  }

  .resumeIcon.MuiSvgIcon-root {
    font-size: 1rem;
    margin-top: -3px;
  }
}

@media only screen and (min-width: 400px) {
  .resumeUpload.MuiButton-root {
    padding: 10px;
    font-size: 13px;
  }
}


@media only screen and (min-width: 600px) {
  .resumeUpload.MuiButton-root {
    padding: 10px;
    font-size: 14px;
  }
}


@media only screen and (min-width: 768px) {
  .resumeIcon.MuiSvgIcon-root {
    font-size: 1.5rem;
    margin-top: -3px;
  }
}


@media only screen and (min-width: 912px) {}


@media only screen and (min-width: 1200px) {
  .resumeUpload.MuiButton-root {
    padding: 10px;
    font-size: 16px;
  }

  .resumeUpload.MuiButton-root:hover {
    padding: 10px;
    font-size: 16px;
  }
}