.topsapce-banner {
  margin-top: 30px;
  transition: 0.5s;
}

.upload-btn-add {
  border: 2px solid #108fb2 !important;
  color: #108fb2 !important;
  font-weight: 700 !important;
  font-family: "Barlow-Bold" !important;
  font-size: 16px !important;
  padding: 5px 10px !important;
  border-radius: 50px !important;
}

.section-title-add {
  padding: 20px 0px;
}

.section-title-add h2 {
  font-size: 18px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;

  text-transform: uppercase;
  color: #ce2f6e;
  font-family: "Poppins", sans-serif;
}

.section-title-add h2::after {
  content: "";
  width: 60px;
  height: 1px;
  display: inline-block;
  background: #0675a2;
  margin: 5px 10px;
}

.banner-creation {
  box-shadow: 0px 0px 8px 2px rgb(31 29 29 / 20%);
  padding: 30px;
  border-radius: 10px;
  transition: 0.3s;
}
.btncenter-for-banner {
  display: none;
  place-items: center;
  visibility: hidden;
}
.btncenter-for-banner-2 {
  display: grid;
  place-items: center;
  visibility: visible;
}
.btncenter-for-banner-conformadd {
  display: grid;
  place-items: center;
}
.banner-div {
  position: relative;
  box-shadow: 0px 0px 2px 2px rgb(31 29 29 / 10%);
  border-radius: 15px;
}
.banner-div img {
  border-radius: 15px;
}

.image-box-banner {
  position: relative;
}
.ab-banner {
  position: absolute;
}

.image-box-banner .center_frist_banner-header {
  top: 20%;
  left: 5%;
  width: 350px;
}
.center_frist_banner-header text {
  font-size: xx-large;
  color: #0675a2;
  text-transform: uppercase;
}
.center_frist_banner-header .uniqueword {
  background-color: #0675a2;
  font-size: xx-large;
  font-weight: 800;
  color: white;
  width: 250px;
  padding: 0px 5px;
}
.center_frist_banner-header text:nth-child(3) {
  color: #ce2f6e;
  font-size: xx-large;
  font-weight: 900;
}
.center_frist_banner-sententens {
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 320px;
}
.center_frist_banner-sententens p {
  font-size: larger;
  text-align: center;
  font-family: Poppins-Regular;
  color: #403c3c;
}

.center_frist_banner-contactus {
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 350px;
  justify-content: space-between;
}
.center_frist_banner-contactus .ali-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.ali-banner {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ali-banner text {
  font-family: Poppins-Regular;
  color: #403c3c;
  font-size: small;
}

.center_frist_banner-sidecontect {
  right: 12%;
  bottom: 12%;
}
.center_frist_banner-sidecontect text {
  font-style: italic;
  font-size: x-large;
  font-weight: 800;
  color: white;
}

.center_frist_banner-offers {
  top: 50%;
  left: 78.5%;
  transform: translate(-50%, -50%);
}
.center_frist_banner-offers text {
  font-size: 45px;
  font-weight: 800;
  color: white;
}

.center_secound_banner-header {
  top: 50%;
  left: 28%;
  transform: translate(-50%, -50%);
  width: 385px;
}
.center_secound_banner-header text:nth-child(1) {
  font-size: 37px;
  color: #ec6dc5;
  text-transform: capitalize;
  font-weight: 600;
  display: block;
  margin-bottom: -7px;
  font-family: Poppins-Regular;
}
.center_secound_banner-header text:nth-child(2) {
  display: block;
  font-size: 41px;
  color: #ec6dc5;
  text-transform: capitalize;
  font-weight: 800;
  line-height: 35px;
  font-family: Poppins-SemiBold;
  /* -webkit-text-stroke: 2px #fff; */
}
.center_secound_banner-sidecontect {
  top: 54%;
  left: 70%;
  transform: translate(-50%, -50%);
  width: 320px;
}
.center_secound_banner-sidecontect p {
  font-size: larger;
  text-align: center;
  font-family: Poppins-Regular;
  color: #403c3c;
}

.center_secound_banner-contactus {
  top: 80%;
  left: 70%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 350px;
  justify-content: space-between;
}
.center_secound_banner-contactus .ali-banner-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.center_3rd_banner-header {
  top: 45%;
  left: 21%;
  transform: translate(-50%, -50%);
  width: 375px;
}

.center_3rd_banner-header text:nth-child(1) {
  font-size: 32px;
  color: #ed1970;
  text-transform: capitalize;
  display: block;
  font-weight: 400;
  margin-bottom: -7px;
  font-family: Poppins-Regular;
}
.center_3rd_banner-header text:nth-child(2) {
  font-size: 40px;
  line-height: 40px;
  font-family: Poppins-SemiBold;
  color: #0675a2;
}
.center_3rd_banner-sidecontect {
  top: 63%;
  left: 22%;
  transform: translate(-50%, 50%);
}

.center_3rd_banner-sidecontect text {
  color: white;
  font-size: 18px;
  font-family: Poppins-Regular;
}

.center_3rd_banner-contactus {
  top: 90%;
  left: 20%;
  transform: translate(-50%, -70%);
  display: flex;
  width: 350px;
  justify-content: space-between;
}
.center_3rd_banner-contactus .ali-banner-3 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.center_3rd_banner-offers {
  top: 60%;
  left: 73%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 320px;
}

.center_3rd_banner-offers text:nth-child(1) {
  font-size: 45px;
  font-weight: 800;
  color: white;
  font-style: italic;
  display: block;
  padding-left: 25px;
}

.center_3rd_banner-offers text:nth-child(2) {
  font-size: 27px;
  font-weight: 800;
  color: white;
  font-style: italic;
  display: block;
}
/* cenetr is over */

.sideimage-banner-1-header {
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  color: white;
}
.sideimage-banner-1-header text:nth-child(1) {
  display: block;
  font-size: 10px;
  text-transform: uppercase;
  font-family: Poppins-Regular;
  font-size: 10px;
}
.sideimage-banner-1-header text:nth-child(2) {
  display: block;
  text-transform: uppercase;
  font-family: Poppins-SemiBold;
  width: 100%;
  font-size: 12px;
  line-height: 12px;
}
.sideimage-banner-1-centercontect {
  top: 64%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  line-height: 15px;
}
.sideimage-banner-1-centercontect text:nth-child(1) {
  display: block;
  text-transform: uppercase;
  font-size: 12px;
  font-family: Poppins-Regular;
  font-weight: 600;
}
.sideimage-banner-1-centercontect text:nth-child(2) {
  display: block;
  text-transform: uppercase;
  font-size: 21px;
  font-family: Poppins-SemiBold;
}
.sideimage-banner-1-centercontect text:nth-child(3) {
  display: block;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 2px;
  font-family: Poppins-SemiBold;
}
.sideimage-banner-1-centercontect p {
  margin: 0;
  font-size: 8px;
  font-family: Poppins-Regular;
  line-height: 8px;
  text-align: left;
  word-break: break-all;
  width: 100px;
  margin-left: 10px;
}
.sideimage-banner-1-contactus {
  top: 81%;
  left: 50%;
  transform: translate(-50%, 50%);
  display: flex;
}
.sideimage-banner-1-contactus text {
  font-family: Poppins-Regular;
  font-size: 12px;
  font-weight: 700;
  color: #0675a2;
}
.sideimage-banner-1-contactus-2 {
  top: 85%;
  left: 50%;
  transform: translate(-50%, 50%);
}
.sideimage-banner-1-contactus-2 {
  color: white;
}
.sideimage-banner-1-offers {
  top: 21%;
  left: 50%;
  transform: translate(-50%, 50%);
}
.sideimage-banner-1-offers text {
  font-size: 33px;
  font-weight: 800;
  color: #0675a2;
  font-style: italic;
  display: block;
}

.sideimage-banner-2-centercontect {
  top: 64%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  line-height: 20px;
}
.sideimage-banner-2-centercontect text:nth-child(1) {
  display: block;
  text-transform: uppercase;
  font-size: 12px;
  font-family: Poppins-Regular;
  font-weight: 600;
  color: #0675a2;
}
.sideimage-banner-2-centercontect text:nth-child(2) {
  display: block;
  text-transform: uppercase;
  font-size: 15px;
  font-family: Poppins-SemiBold;
  color: #0675a2;
}
.sideimage-banner-2-centercontect text:nth-child(3) {
  display: block;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 2px;
  font-family: Poppins-SemiBold;
  color: #0675a2;
}
.sideimage-banner-2-centercontect p {
  margin: 0;
  font-size: 8px;
  font-family: Poppins-Regular;
  line-height: 10px;
  text-align: left;
  word-break: break-all;
  width: 100px;
  margin-left: 10px;
}

.sideimage-banner-2-contactus {
  top: 74%;
  left: 50%;
  transform: translate(-50%, 50%);
}
.sideimage-banner-2-contactus text {
  font-family: Poppins-Regular;
  font-size: 10px;
  font-weight: 700;
  color: #ffffff;
}
.sideimage-banner-2-header {
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  color: #0675a2;
}
.sideimage-banner-2-header text:nth-child(1) {
  display: block;
  font-size: 18px;
  text-transform: uppercase;
  font-family: Poppins-Regular;
  font-weight: 600;
}
.sideimage-banner-2-header text:nth-child(2) {
  display: block;
  text-transform: uppercase;
  font-family: Poppins-SemiBold;
  width: 100%;
  color: #ec2172;;
  font-size: 14px;
  line-height: 30px;
}
.sideimage-banner-2-contactus-2 {
  top: 79%;
  left: 50%;
  transform: translate(-50%, 50%);
}
.sideimage-banner-2-contactus-2 {
  color: #0675a2;
}

.sideimage-banner-3-centercontect {
  top: 69%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  line-height: 20px;
}
.sideimage-banner-3-centercontect text:nth-child(1) {
  display: block;
  text-transform: uppercase;
  font-size: 12px;
  font-family: Poppins-Regular;
  font-weight: 600;
  color: #0675a2;
}
.sideimage-banner-3-centercontect text:nth-child(2) {
  display: block;
  text-transform: uppercase;
  font-size: 21px;
  font-family: Poppins-SemiBold;
  color: #0675a2;
}
.sideimage-banner-3-centercontect text:nth-child(3) {
  display: block;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 2px;
  font-family: Poppins-SemiBold;
  color: #0675a2;
}
.sideimage-banner-3-centercontect p {
  margin: 0;
  font-size: 8px;
  font-family: Poppins-Regular;
  line-height: 10px;
  text-align: left;
  word-break: break-all;
  width: 100px;
  margin-left: 10px;
}

.sideimage-banner-3-contactus {
  top: 77%;
  left: 50%;
  transform: translate(-50%, 50%);
  margin-left: 11px;
}
.sideimage-banner-3-contactus text {
  font-family: Poppins-Regular;
  font-size: 14px;
  font-weight: 700;
  color: #0675a2;
}

.sideimage-banner-3-contactus-2 {
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.sideimage-banner-3-contactus-2 {
  color: #0675a2;
}

/* sideimg over */

.popup-banner-1-header {
  top: 77%;
  left: 25%;
  transform: translate(-50%, -50%);
  width: 250px;
  text-align: center;
}
.popup-banner-1-header text:nth-child(1) {
  font-size: 29px;
  font-family: Poppins-SemiBold;
  color: #0675a2;
  line-height: 28px;
  display: block;
}

.popup-banner-1-header text:nth-child(2) {
  font-size: 19px;
  font-family: Poppins-Regular;
  color: #0675a2;
  display: block;
  margin-top: 15px;
}
.popup-banner-1-contact {
  top: 32%;
  left: 53%;
  transform: translate(-50%, -50%);
}
.popup-banner-1-contact text {
  background-color: #e91069;
  color: white;
  font-size: 14px;
  font-weight: 700;
  font-family: Poppins-Regular;
  padding: 5px;
}
.popup-banner-1-contact-detalis {
  top: 43%;
  left: 66%;
  transform: translate(-50%, -50%);
  width: 235px;
}
.popup-banner-1-contact-detalis text:nth-child(1) {
  display: block;
  font-family: Poppins-Regular;
  color: #0675a2;
  font-size: 13px;
}
.popup-banner-1-contact-detalis text:nth-child(2) {
  display: block;
  font-family: Poppins-Regular;
  color: #0675a2;
  font-size: 13px;
}
.popup-banner-1-contact-detalis text:nth-child(3) {
  display: block;
  font-family: Poppins-Regular;
  color: #0675a2;
  font-size: 13px;
}
.popup-banner-1-aboutus {
  top: 67%;
  left: 78%;
  transform: translate(-50%, -50%);
  width: 180px;
  text-align: center;
}
.popup-banner-1-aboutus text {
  color: #0675a2;
  font-size: 15px;
  font-family: Poppins-SemiBold;
  letter-spacing: 3px;
}
.popup-banner-1-aboutus-contect {
  top: 78%;
  left: 79%;
  transform: translate(-50%, -50%);
  width: 180px;
  font-size: 11px;
  font-family: Poppins-Regular;
  color: #0675a2;
}

.popup-banner-2-header {
  top: 35%;
  left: 25%;
  transform: translate(-50%, -50%);
  width: 250px;
  line-height: 26px;
}
.popup-banner-2-header text:nth-child(1) {
  display: block;
  font-family: Poppins-Regular;
  font-size: 22px;
  color: #0675a2;
}
.popup-banner-2-header text:nth-child(2) {
  display: block;
  font-family: Poppins-Regular;
  font-size: 40px;
  color: #0675a2;
}
.popup-banner-2-aboutus-contect {
  top: 60%;
  left: 23%;
  transform: translate(-50%, -50%);
  width: 230px;
  font-size: 12px;
  font-family: Poppins-Regular;
  color: #0675a2;
}
.popup-banner-2-aboutus-contactus {
  top: 77%;
  left: 19%;
  transform: translate(-50%, 50%);
}
.popup-banner-3-header {
  top: 35%;
  left: 20%;
  transform: translate(-50%, -50%);
  width: 200px;
}
.popup-banner-3-header text:nth-child(1) {
  font-size: 30px;
  color: white;
  font-family: Poppins-Regular;
  display: block;
}
.popup-banner-3-header text:nth-child(2) {
  font-weight: 900;
  font-size: 40px;
  line-height: 38px;
  font-family: Poppins-SemiBold;
  color: white;
}
.popup-banner-3-btn {
  top: 60%;
  left: 20%;
  transform: translate(-50%, -50%);
}
.popup-banner-3-btn text {
  text-transform: uppercase;
  font-family: Poppins-SemiBold;
  font-size: 20px;
  padding: 5px 10px;
  background-color: white;
  color: #0675a2;
}
.popup-banner-3-aboutus {
  top: 80%;
  left: 9%;
}
.popup-banner-3-aboutus text {
  font-family: Poppins-SemiBold;
  font-size: 22px;
  color: #0675a2;
}
.popup-banner-3-aboutus p {
  width: 510px;
  margin: 0;
  margin-top: 4px;
  font-family: Poppins-Regular;
  font-size: 13px;
}
.popup-banner-3-contactus {
  display: flex;
  gap: 20px;
  top: 76%;
  left: 24%;
  transform: translate(-50%, -50%);
}

.popup-banner-3-contactus .ali-banner-3 {
  display: flex;
  gap: 5px;
}
.popup-banner-3-contactus .ali-banner-3 div:nth-child(2) {
  font-size: 10px;
  color: #403c3c;
}
.flash-banner-1-header {
  top: 29%;
  left: 26%;
  transform: translate(-50%, -50%);
  line-height: 17px;
}
.flash-banner-1-header text:nth-child(1) {
  display: block;
  font-family: Poppins-Regular;
  text-transform: uppercase;
  color: #ec6dc5;
}
.flash-banner-1-header text:nth-child(2) {
  display: block;
  font-family: Poppins-SemiBold;
  text-transform: uppercase;
  color: #038fc7;
}
.flash-banner-1-contect {
  top: 44%;
  left: 33%;
  transform: translate(-50%, -50%);
  width: 120px;
  font-size: 8px;
  line-height: 10px;
  font-family: Poppins-Regular;
  color: #0675a2;
}
.flash-banner-1-btn {
  top: 56%;
  left: 11%;
  display: block;
  font-family: Poppins-SemiBold;
  text-transform: uppercase;
  font-size: 10px;
  color: #ffffff;
  font-style: italic;
}
.flash-banner-1-title {
  top: 65.4%;
  left: 20%;
  display: block;
  font-family: Poppins-SemiBold;
  text-transform: uppercase;
  font-size: 10px;
  color: #ffffff;
  font-style: italic;
  line-height: 26px;
}
.flash-banner-1-title text {
  display: block;
}
.flash-banner-1-contact {
  display: flex;
  top: 93%;
  flex-direction: column;
  left: 42%;
  transform: translate(-50%, -50%);
  gap: 2px;
}
.ali-banner-flash {
  display: flex;
  color: white;
  font-size: 9px;
  gap: 5px;
  font-family: Poppins-Regular;
}
.flash-banner-2-header {
  top: 20%;
  left: 38%;
  transform: translate(-50%, -50%);
  line-height: 20px;
}
.flash-banner-2-header text:nth-child(1) {
  display: block;

  font-family: Poppins-Regular;
  text-transform: uppercase;
  color: #ec6dc5;
}
.flash-banner-2-header text:nth-child(2) {
  display: block;
  font-family: Poppins-SemiBold;
  text-transform: uppercase;
  color: #038fc7;
  line-height: 18px;
}
.flash-banner-2-aboutus {
  top: 47%;
  left: 29%;
  transform: translate(-50%, -50%);
  width: 107px;
}
.flash-banner-2-aboutus p {
  font-family: Poppins-Regular;
  font-size: 7px;
}
.flash-banner-2-btn {
  top: 67%;
  left: 20%;
  transform: translate(-50%, -50%);
}
.flash-banner-2-btn text {
  text-transform: uppercase;
  background-color: #038fc7;
  padding: 5px;
  color: white;
  font-size: 10px;
  font-family: Poppins-SemiBold;
}
.flash-banner-2-contactus {
  top: 86%;
  left: 36%;
  line-height: 20px;
  transform: translate(-50%, -50%);
}
.flash-banner-2-contactus .ali-banner-flash {
  color: #403c3c !important;
  justify-content: flex-start;
}
.flash-banner-2-contactus .ali-banner-flash div:nth-child(1) {
  font-size: smaller;
}

.flash-banner-3-header {
  top: 29%;
  left: 28%;
  transform: translate(-50%, -50%);
  line-height: 25px;
}

.flash-banner-3-header text:nth-child(1) {
  font-family: Poppins-SemiBold;
  font-size: 20px;
  display: block;
  text-transform: uppercase;
  color: #ec6dc5;
}
.flash-banner-3-header text:nth-child(2) {
  font-family: Poppins-SemiBold;
  font-size: 30px;
  display: block;
  text-transform: uppercase;
  color: #038fc7;
}

.flash-banner-3-box-text {
  top: 38%;
  left: 25%;
  transform: translate(-50%, 50%);
}
.flash-banner-3-box-text text {
  font-size: 10px;
  font-family: Poppins-Regular;
  color: white;
  text-transform: uppercase;
}
.flash-banner-3-aboutus {
  top: 40%;
  left: 30%;
  transform: translate(-50%, 50%);
  width: 115px;
}
.flash-banner-3-aboutus p {
  font-size: 7px;
  font-family: Poppins-Regular;
}

.flash-banner-3-btn {
  top: 79%;
  left: 14.5%;
  transform: translate(-50%, 50%);
}
.flash-banner-3-btn text {
  font-family: Poppins-SemiBold;
  font-size: 7px;
  color: white;
  text-transform: uppercase;
}
.flash-banner-3-contact {
  top: 10%;
  left: 37%;
  transform: translate(-50%, -50%);
}
.flash-banner-3-contact .ali-banner-flash {
  gap: 3px;
}
.flash-banner-3-contact .ali-banner-flash div:nth-child(2) {
  color: #403c3c;
}
.flash-banner-3-callus {
  top: 80%;
  left: 25%;
  transform: translate(-50%, -50%);
}

.flash-banner-3-callus text:nth-child(1) {
  font-family: Poppins-SemiBold;
  font-size: 9px;
  color: #038fc7;
  text-transform: uppercase;
  margin-right: 5px;
}
.flash-banner-3-callus text:nth-child(2) {
  font-size: 9px;
  font-weight: 700;
  color: #403c3c;
  font-family: Poppins-Regular;
}
.flash-banner-3-logo img {
  width: 100%;
}
.flash-banner-3-logo {
  top: 53%;
  left: 80%;
  transform: translate(-50%, -50%);
  width: 35%;
  overflow: hidden;
  border-radius: 10%;
}

.center_frist_banner-logo {
  top: 24%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8%;
  overflow: hidden;
  border-radius: 10%;
}
.center_frist_banner-logo img {
  width: 100%;
}

.sideimage-banner-1-logo {
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 45%;
  overflow: hidden;
  border-radius: 10%;
}
.sideimage-banner-1-logo img {
  width: 100%;
}

.popup-banner-1-logo {
  top: 25%;
  left: 20%;
  transform: translate(-50%, -50%);
  width: 20%;
  overflow: hidden;
  border-radius: 10%;
}
.popup-banner-1-logo img {
  width: 100%;
}

.flash-banner-1-logo {
  top: 13%;
  left: 20%;
  transform: translate(-50%, -50%);
  width: 15%;
  overflow: hidden;
  border-radius: 10%;
}

.flash-banner-1-logo img {
  width: 100%;
}

.center_secound_banner-logo {
  top: 24%;
  left: 70%;
  transform: translate(-50%, -50%);
  width: 6%;
  overflow: hidden;
  border-radius: 10%;
}
.center_secound_banner-logo img {
  width: 100%;
}

.sideimage-banner-2-logo{
top: 10%;
left: 50%;
transform: translate(-50%, -50%);
width: 60%;
overflow: hidden;
border-radius: 10%;
}
.sideimage-banner-2-logo img {
width: 100%;
}
.popup-banner-2-logo{
  top: 15%;
  left: 13%;
  transform: translate(-50%, -50%);
  width: 13%;
  overflow: hidden;
  border-radius: 10%
}
.popup-banner-2-logo img{
  width: 100%;
}

.flash-banner-2-logo{
  top: 53%;
  left: 77%;
  transform: translate(-50%, -50%);
  width: 30%;
  overflow: hidden;
  border-radius: 10%;
}

.flash-banner-2-logo img{
  width: 100%;
}

.center_3rd_banner-logo{
  top: 24%;
    left: 75%;
    transform: translate(-50%, -50%);
    width: 6%;
    overflow: hidden;
    border-radius: 10%;
}
.center_3rd_banner-logo img {
  width: 100%;
}

.sideimage-banner-3-logo{
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 45%;
  overflow: hidden;
  border-radius: 10%;
  }
  .sideimage-banner-3-logo img {
  width: 100%;
  }

  .popup-banner-3-logo {
    top: 12%;
    left: 10%;
    transform: translate(-50%, -50%);
    width: 10%;
    overflow: hidden;
    border-radius: 10%;
  }
  .popup-banner-3-logo img {
    width: 100%;
  }
  
  .top-banner-1-header{
    top: 54%;
      left: 12%;
      transform: translate(-50%, -50%);
      font-family: "Poppins-SemiBold";
      color: #194176;
      width: 100px;
      line-height: 13px;
      display: none;
  }
  .top-banner-1-contact{
    top: 80%;
    left: 42%;
    transform: translate(-50%, -50%);
    font-family:"Poppins-SemiBold";
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .top-banner-1-contect{
    top: 53%;
      left: 40%;
      transform: translate(-50%, -50%);
      width: 135px;
      font-size: 11px;
      line-height: 10px;
      font-family: Poppins-Regular;
      color: #f3f9ff;
      text-align: center;
  }
  .top-banner-1-logo{
    top: 23%;
    left: 40%;
    transform: translate(-50%, -50%);
    width: 30px;
  }
  .top-banner-1-logo img{
    width: 100%;
  }
  .top-banner-1-title{
    top: 50%;
    right: 21%;
    transform: translate(26%, -50%);
    display: flex;  
    flex-direction: column-reverse;
    align-items: center;
    color: white;
    
  }
  
  .top-banner-1-title text:nth-child(2){
    font-family: "Poppins-Bold";
      color: #194176;
      background-color: white;
      padding: 0px;
      border-radius: 50%;
      font-size: larger;
      width: 65px;
      height: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-style: italic;
  }
  .top-banner-1-title text:nth-child(1){
    font-family: "Poppins-Bold";
      font-style: italic;
      font-size: small;
      color: #132a3a;
  }
  .top-banner-1-btn{
    top: 34%;
      left: 12%;
      transform: translate(-50%, -50%);
      font-family: "Poppins-SemiBold";
      color: #194176;
      width: 100px;
      line-height: 13px;
      display: none;
  }
  
  .top-banner-2-header{
    top: 67%;
      left: 20%;
      transform: translate(-50%, -50%);
      font-family: "Poppins-SemiBold";
      color: #194176;
      width: 180px;
      line-height: 13px;
  }
  .top-banner-2-btn{
    top: 50%;
      left: 13%;
      transform: translate(-50%, -50%);
      font-family: "Poppins-SemiBold";
      color: #000000;
      width: 100px;
      line-height: 13px;
  }
  .top-banner-2-contect{
    top: 60%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 137px;
      font-size: 11px;
      line-height: 12px;
      font-family: Poppins-Regular;
      text-align: center;
  }
  .top-banner-2-contact{
    top: 90%;
      left: 53%;
      transform: translate(-50%, -50%);
      display: flex;
      color: #194176;
      gap: 16px
  }
  
  .top-banner-2-contact .ali-banner-flash{
    color: #194176;
  }
  
  .top-banner-2-title{
    top: 55%;
      right: 15%;
      transform: translate(50%, -50%);
      display: flex;
      color: #ffff;
      background-color: rgba(25, 65, 118,0.9);
      gap: 16px;
      padding: 7px 15px;
      border-radius: 3px;
  }
  .top-banner-2-title{
    font-family: "Poppins-Regular";
    font-size: 10px;
  }
  
  .top-banner-3-logo{
    width: 10%;
    top: 50%;
    right: 10%;
    transform: translate(50%, -50%);
  }
  .top-banner-3-logo img{
    width: 100%;
  }
  
  .top-banner-3-header{
    top: 40%;
    left: 22%;
    transform: translate(-50%, -50%);
    width: 220px;
  }
  
  .top-banner-3-header text{
        text-transform: uppercase;
        font-family: "Poppins-Bold";
        color: white;
        font-size: 25px;
        line-height: 13px;
        font-weight:900 !important
  }
  .top-banner-3-btn{
    top: 76%;
      left: 11%;
      transform: translate(-49%, -50%);
  }
  .top-banner-3-btn text{
    background-color: white;
      padding: 3px 20px;
      font-family: 'Poppins-Regular';
      font-weight: 600;
      color: black;
      border-radius: 2px;
      font-size: 12px;
  }
  
  .bottomfooter-banner-1-header{
    top: 59%;
      left: 17%;
      transform: translate(-50%, -50%);
      font-family: "Poppins-SemiBold";
      color: #eaecf0;
      width: 190px;
      line-height: 13px;
      font-size: 20px;
  }
  .bottomfooter-banner-1-contact{
    top: 80%;
    left: 53%;
    transform: translate(-50%, -50%);
    font-family:"Poppins-SemiBold";
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
  
  .bottomfooter-banner-1-contect{
    top: 53%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 135px;
      font-size: 11px;
      line-height: 10px;
      font-family: Poppins-Regular;
      color: #f3f9ff;
      text-align: center;
  }
  .bottomfooter-banner-1-logo{
    top: 23%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
  }
  .bottomfooter-banner-1-logo img{
    width: 100%;
  }
  .bottomfooter-banner-1-title{
    top: 45%;
    right: 8%;
    transform: translate(26%, -50%);
    display: flex;  
    flex-direction: column-reverse;
    align-items: center;
    color: white;
    
  }
  
  .bottomfooter-banner-1-title text:nth-child(2){
    font-family: "Poppins-Bold";
    color: #194176;
    background-color: white;
    padding: 0px;
    border-radius: 50%;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;
  }
  .bottomfooter-banner-1-title text:nth-child(1){
    font-family: "Poppins-bold";
      font-style: italic;
      font-size: large;
      color: #ffde59;
      background-color: #132a3a;
      padding: 2px 15px;
      border-radius: 3px;
  }
  .bottomfooter-banner-1-btn{
    top: 37%;
      left: 12%;
      transform: translate(-55%, -50%);
      font-family: "Poppins-SemiBold";
      color: #e6e9ec;
      width: 100px;
      line-height: 13px;
      font-size: 22px;
  }
  
  .bottomfooter-banner-2-title{
    top: 45%;
    right: 8%;
    transform: translate(26%, -50%);
    display: flex;  
    flex-direction: column-reverse;
    align-items: center;
    color: white;
    
  }
  
  .bottomfooter-banner-2-title text:nth-child(2){
    font-family: "Poppins-Bold";
    color: white;
    
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;
  }
  .bottomfooter-banner-2-title text:nth-child(1){
    font-family: "Poppins-bold";
      font-style: italic;
      font-size: large;
      color: #ff8000;
      
      border-radius: 3px;
  }
  
  .bottomfooter-banner-3-contact{
    top: 80%;
    left: 47%;
    transform: translate(-50%, -50%);
    font-family:"Poppins-SemiBold";
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
  
  .bottomfooter-banner-3-contect{
    top: 53%;
      left: 45%;
      transform: translate(-50%, -50%);
      width: 135px;
      font-size: 11px;
      line-height: 10px;
      font-family: Poppins-Regular;
      color: #f3f9ff;
      text-align: center;
  }
  .bottomfooter-banner-3-logo{
    top: 23%;
    left: 45%;
    transform: translate(-50%, -50%);
    width: 30px;
  }
  .bottomfooter-banner-3-logo img{
    width: 100%;
  }
  
  .instagram-banner-1-header {
    top: 50%;
    left: 10%;
    width: 260px;
    word-break: break-word;
    font-size: 35px;
    font-family: 'Barlow-Bold';
}
  .instagram-banner-1-contect{
    top: 73%;
    left: 2%;
    width: 281px;
    font-size: 15px;
    font-family: 'Poppins-Regular';
    text-align: center;
  }
  .instagram-banner-1-btn{
    top: 42%;
    left: 10%;
    width: 281px;
    font-size: 26px;
    font-family: 'Barlow';
    color: #000000;
  }
  .instagram-banner-1-contact{
    top: 90%;
    left: 10%;
    width: 281px;
    font-size: 26px;
    font-family: 'Barlow';
    color: #000000;
  }
  .instagram-banner-1-contact .ali-banner-flash{
    color: #000000;
    font-size: 11px;
  }

  .instagram-banner-1-logo{
    top: 32%;
    left: 29%;
    transform: translate(-50%, -50%);
    width: 68px;
  }
  .instagram-banner-1-logo img{
    width: 100%;
  }
  .instagram-banner-1-title{
   top: 70%;
    left: 26%;
    transform: translate(-50%, -50%);
    width: 90px;
    background: #e35224;
    text-align: center;
    padding: 3px;
    color: white;
    font-size: 12px;
    border-radius: 3px;
  }
  .facebook-banner-1-logo{
    width: 55px;
    top: 10%;
    right: 10%;
  }
  .facebook-banner-1-logo img{
    width: 100%;
  }
  .facebook-banner-1-btn{
    top: 10%;
    left: 19%;
    font-size: 25px;
    font-family: 'Barlow';
    transform: translate(-8px, 2px);
    color: black;
  }
  .facebook-banner-1-header{
    top: 22%;
    left: 15%;
    font-size: 25px;
    font-family: 'Poppins-Rregular';
    transform: translate(-8px, 2px);
    color: #ffffff;
    width: 140px;
  }
  .facebook-banner-1-contect{
    top: 37%;
    left: 15%;
    font-size: 12px;
    font-family: 'Poppins';
    transform: translate(-8px, 2px);
    color: #ffffff;
    width: 163px;
  }
  .facebook-banner-1-title{
    top: 62%;
    left: 17%;
    font-size: 15px;
    font-family: 'Barlow-Bold';
    transform: translate(-6px, 2px);
    color: #ffffff;
    width: 163px;
  }

  .facebook-banner-1-contact{
    top: 79%;
    left: 12%;
    gap: 4px;
    display: flex;
    flex-direction: column;
  }




  .facebookpoppup-banner-1-logo{
    width: 70px;
    top: 6%;
    left: 13%;
  }
  .facebookpoppup-banner-1-logo img{
    width: 100%;
  }
  .facebookpoppup-banner-1-btn{
    top: 23%;
    left: 15%;
    font-size: 25px;
    font-family: 'Poppins-semibold';
    transform: translate(-8px, 2px);
    color: #07DBF2;
  }
  .facebookpoppup-banner-1-header{
    top: 31%;
    left: 15%;
    font-size: 25px;
    font-family: 'Poppins-Rregular';
    transform: translate(-8px, 2px);
    color: #ffffff;
    width: 140px;
  }
  .facebookpoppup-banner-1-contect{
    top: 43%;
    left: 15%;
    font-size: 12px;
    font-family: 'Poppins-Regular';
    transform: translate(-8px, 2px);
    color: #07dbf2;
    width: 163px;
  }
  .facebookpoppup-banner-1-title{
    top: 64%;
    left: 15%;
    font-size: 15px;
    font-family: 'Barlow-Bold';
    transform: translate(-6px, 2px);
    color: #ffffff;
    width: 163px;
  }

  .facebookpoppup-banner-1-contact{
    top: 78%;
    left: 15%;
    gap: 4px;
    display: flex;
    flex-direction: column;
  }


.space-between {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

/* inputs */

.banner-inputs {
  margin-top: 20px;
}
.inuputsgapsforbanner {
  margin-top: 25px;
  display: flex;
  gap: 20px;
}
.inuputsgapsforbannererror {
  /* display: grid;
  grid-template-columns: 59% 41%;
  text-align: right; */
  display: flex;
  justify-content: end;
}
.inuputsgapsforbannererror p{
  width: 48.2%;
  margin: 0;
}

.inuputsgapsforbannererror2 {
  display: flex;
  justify-content: start;
}
.inuputsgapsforbannererror2 p{
  margin: 0;
}
/* Payment page*/
.PaymentDetailsCard.MuiCard-root {
  box-shadow: 0px 4px 8px rgba(109, 109, 109, 0.5);
  border: 1px solid #bab8b8d7;
  border-radius: 10px;
}

.PaymentDetailsCard.MuiCard-root:hover {
  box-shadow: 0px 4px 8px rgba(43, 149, 255, 0.5);
  border: 1px solid #89c4ff;
}

/* paymentpage */

.paymentpage {
  display: grid;
  grid-template-columns: 60% 40%;
  height: 100%;
}

.info-header h3{
 font-size: 38px;
 margin: 0px;
 line-height: 35px;
 font-family: Barlow-Bold;
 letter-spacing: 5px;
}
.info-header{
  margin-top: 35px;
}
.info-sentence{
  font-family: Poppins-Regular;
    font-size: 14px;
    margin: 10px 0px;
}

.font-mark{
  font-weight: 700 !important;
  transition: 0.3s !important;
}

.font-mark:hover{
  text-decoration: underline;
  color: #038fc7;
  cursor: pointer;
}

.info-payment ul li{
  display: flex;
  list-style: none;
}


.template-price {
  display: flex;
  justify-content: center;
  align-items: center;
}
.template-price .price-card {
  padding: 25px 20px;
  box-shadow: 0px 0px 8px 2px rgba(43, 149, 255, 0.5);
  border-radius: 10px;
  text-align: center;
}
.amount-alignment {
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.amount-alignment span {
  color: #038fc7;
  font-size: 40px;
  font-family:Poppins-Regular;
}

.price-card h4,
.price-card p {
  margin: 10px;
}
.price-card h4 {
  font-family: Poppins-SemiBold;
}
.price-card p{
  font-family: Poppins-Regular;
}
.list-the-info{
  text-align: left;
}
.list-the-info ul li{
  padding: 10px 0px ;
  font-family:Poppins-Regular;
}
.list-the-info ul{
  margin-top: 10px;
}

.list-the-info ul {
  list-style: none;
}

.list-the-info ul li::before {
  content: "\2022";
  color: #ec2172;
  font-weight: 800;
  font-size: 18px;
  display: inline-block; 
  width: 1em;
  margin-left: -1em;
}
.box-for-priceing{
  border-left: 4px solid #ce2f6e;
    padding: 20px 30px;
    border-radius: 10px;
    transition: 0.3s;
    margin-top: 25px;
    background-color: #f3f9ff;
}
.box-for-priceing p{
  font-family: "Poppins-Regular";
}
.box-for-priceing span{
  font-family: "Poppins-SemiBold";
  color: #038fc7;
  font-size: 17px;
}

