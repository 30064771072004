
.aadharName.MuiTypography-root {
  color:#2e7d32;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  border: none;
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.familyDetailHead.MuiTableRow-root .MuiTableCell-root {
    font-weight: bold;
    background-color: #339fcd;
    color: #ffffff;
    font-family: Poppins-Medium;
  }

 .needSupportTable .MuiTableCell-root{
  padding: 10px;
 }

 .GoBackTooltip{
  background-color: #339fcd;
 }

 .GoBackTooltip.MuiIconButton-root:hover{
  background-color: #1d5c77;
 }

 .needSupportTable.MuiTableCell-root{
  padding: 10px;
 }

 .info1 {
  margin-left: 8px;
  text-align: center;
  margin-bottom: -1.2rem;
  font-size: 0.8rem;
  margin-top: -2px;
}
.info2 {
  margin-top: 1.2rem;
  margin-bottom: -1.2rem;
  font-size: 0.8rem;
  text-align: center;
}

.MoreDetails{
  width: 10%;
  text-decoration: underline;
  cursor: pointer;
}
.MoreDetails:hover{
  color: purple;
}


 @media only screen and (min-width: 250px) {
  .aadharUpload.MuiButton-root {
    padding: 6px;
    font-size: 10px;
    margin-top: 8px;
  }
}

@media only screen and (min-width: 300px) {
  .aadharUpload.MuiButton-root {
    padding: 8px;
    font-size: 12px;
  }

  .aadharIcon.MuiSvgIcon-root {
    font-size: 1rem;
    margin-top: -3px;
  }
}

@media only screen and (min-width: 350px) {
  .FamilyDetailsAlign{
    display: grid;
    grid-template-columns: 50% 75%;
  }

  .FamilyNameDetailsAlign{
    display: flex;
    justify-content: space-between;
  }
  .yesNoEditViewBtn{
    display: flex;
  }
  .aadharUpload.MuiButton-root {
    padding: 8px;
    font-size: 12px;
  }

  .aadharIcon.MuiSvgIcon-root {
    font-size: 1rem;
    margin-top: -3px;
  }
}

@media only screen and (min-width: 400px) {
 
  
  .aadharUpload.MuiButton-root {
    padding: 12px;
    font-size: 14px;
  }
  .aadharIcon.MuiSvgIcon-root {
    font-size: 1.2rem;
    margin-bottom: -2px;
  }
}


@media only screen and (min-width: 600px) {
  .needSupportButton{
    display: flex;
  }
  .aadharUpload.MuiButton-root {
    padding: 10px;
    font-size: 14px;
  }
}


@media only screen and (min-width: 768px) {
  .FamilyDetailsAlign{
    display: grid;
    grid-template-columns: 50% 75%;
  }

  .FamilyNameDetailsAlign{
    display: flex;
    justify-content: space-between;
  }
  .aadharIcon.MuiSvgIcon-root {
    font-size: 1.3rem;
    margin-top: -4px;
  }
  .aadharUpload.MuiButton-root {
    margin-top: 15px;
    margin-left: 15px;
    padding: 6px;
    font-size: 16px;
  }
}


@media only screen and (min-width: 912px) {
  .FamilyDetailsAlign{
    display: grid;
    grid-template-columns: 35% 75%;
  }

  .FamilyNameDetailsAlign{
    display: flex;
    justify-content: space-between;
  }
  .aadharUpload.MuiButton-root {
    margin-top: 15px;
    margin-left: 25px;
    padding: 6px;
    font-size: 16px;
  }

  .aadharUpload.MuiButton-root:hover {
    padding: 6px;
    font-size: 16px;
  }
}


@media only screen and (min-width: 1200px) {

  .FamilyDetailsAlign{
    display: grid;
    grid-template-columns: 25% 75%;
  }

  .FamilyNameDetailsAlign{
    display: flex;
    justify-content: space-between;
  }

  .needSupportHeader{
    display: flex;
  }

  .aadharUpload.MuiButton-root {
    margin-top: 15px;
    margin-left: 10px;
    padding: 6px;
    font-size: 16px;
  }

  .aadharUpload.MuiButton-root:hover {
    padding: 6px;
    font-size: 16px;
  }
}

