 .img-grid{
  position: sticky;
}
.sideimg{
  position: fixed;
  width: 250px;
  height: 100%;
 margin-top: 13vh;
}

.sideimglogo{
  display: flex;
  justify-content: center;
}

.listofsidecontect{
  display: flex;
  gap: 5px;
 
}
.sidecontect{
  width: 100%;
}

#mobile_number::-webkit-inner-spin-button,#mobile_number::-webkit-outer-spin-button{
  -webkit-appearance: none;
}
#mobile_number{
-moz-appearance: textfield;
}

@media only screen and (min-width: 250px) {
  .header {
    font-size: smaller;
  }
  .Box{
    flex-wrap: wrap;
  } 
  .sideimg{
   display: none;
  }
}

@media only screen and (min-width: 300px) {
  .header {
    font-size: smaller;
  }

  .Box{
    flex-wrap: wrap;
  }

  .sideimg{
    display: none;
   }
}

@media only screen and (min-width: 400px) {
  .header {
    font-size: small;
  }
  .Box{
    flex-wrap: wrap;
  }
 
  .sideimg{
    display: none;
   }
 
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .header {
    font-size: medium;
  }

  .Box{
    flex-wrap: nowrap;
  }

  .sideimg{
    
    display: none;
   }
 

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .header {
    font-size: large;
  }
  
  .Box{
    flex-wrap: nowrap;
  }

  /* .sideReg{ 
    display: none;
   } */
 
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 912px) {
  .header {
    font-size: larger;
  }
  .sideimg{
    position: fixed;
    display: block;
    justify-content: center;
  align-items: center;
  width: 250px;
  height: 100%;
  
   }
 
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .header {
    font-size: larger;
  }
  .Box{
    flex-wrap: nowrap;
  }

}




/* ================= new ================= */

.voluter-btn{
  margin-left: 0px !important;
  border-radius: 50px !important;
  
}
.voluter-btn-active{
  margin-left: 0px !important;
  border-radius: 50px !important;
}

.voluter-space{
  display: flex !important;
  gap: 15px !important;
}

.colorfont{
  color: rgb(8, 8, 8);
  font-weight: 600;
}
.icon-active{
  color: #ffff;
}

.voluter-icon{
  color: rgb(8, 8, 8) !important;
  margin-left: 0px !important;
}
.voluter-icon-active{
  margin-left: 0px !important;
}
.MuiButton-startIcon{
  margin-right: 0px !important;
}

.whatsappnumber-active{
  padding: 2px 0px ;
  margin-top: 6px;
}

.whatsappnumber-active p{
  font-family: "Poppins-Regular";
    margin: 0px;
    font-size: 11px;
    font-weight: 500;
}
.checkbox{
  padding: 0px !important;
  margin-right: 3px;
}

.whatsappnumber-active p text {
  color: #25D366;
}