
.topGrid {
  display: flex;
}

.sideGridScrollHide::-webkit-scrollbar {
  display: none;
}

.selected-li {
  list-style-type: none;
  margin-top: 14px;
}

#seekquickactive {
  color: #ed1e70;
}

#seekquickactive1 {
  color: #135c82;
}

.seekerCard {
  background-color: rgba(247, 250, 252, 0.9);
  border: 1px solid rgba(6, 117, 162, 0.5);
  box-shadow: 0 3px 10px rgba(6, 117, 162, 0.7);
  border-radius: 40px 10px 40px 10px;
  color: rgb(0, 0, 0);
  border-bottom: 3px solid rgba(16, 143, 178,0.7);
}

.SideGrid {
  position: fixed;
  padding: 10px 0 0 10px;
}

.linkA {
  color: rgb(19 92 130);
}
.linkA:hover{
  text-decoration: underline;
}

.fullcontent {
  background-color: rgba(6, 117, 162, 0.3);
}

#mobile_number::-webkit-inner-spin-button,
#mobile_number::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

#mobile_number {
  -moz-appearance: textfield;
}

.jobFullContend {
  background-color: #a8cfdf;
}

.recommendedJobCard {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  padding-bottom: 25px;
}

.recommendedJob-InnerCard {
  position: relative;
  margin: 20px 10px 0px 10px;
  color: white;
  padding: 1rem;
  height: auto;
}

.AppliedDiv {
  position: relative;
}

.AppliedButton {
  position: absolute;
  right: 0;
  top: 0;
}

.jobImgLogo {
  margin-top: -20px;
  width: 100px;
  height: 75px;
  border: 1px solid rgb(106 164 222);
}

@media only screen and (min-width: 250px) {
  .Educationfieldsize{
    width: 200px;
  }
  .ResumeName.MuiTypography-root{
    margin-left: 14px;
  }
  .recommendedJobCard {
    grid-template-columns: repeat(1, 1fr);
  }

  .JobDescriptionOverflow.MuiTypography-root {
    display: inline-block;
    width: 300px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  .jobImgLogo {
    margin-top: 10px;
    margin-left: 10px;
    width: 60px;
    height: 50px;
    border: 1px solid rgb(106 164 222);
  }

  .ApplyCard {
    position: relative;
  }

  .ApplyButton {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .searchInput.MuiTextField-root {
    width: 180px;
  }

  .jobValues {
    display: block !important;
  }

  #hide {
    display: none;
  }

  #mobileView {
    display: block;
    z-index: 99;
  }

  .updateProfileImage {
    height: 60px;
    width: 60px;
    border-radius: 50px;
    border: 3px solid green;
  }

  .cameraicon.MuiSvgIcon-root {
    margin-left: 21px;
    margin-top: -13px;
  }

  .CircularProgressIcon.MuiCircularProgress-root {
    margin-left: 1.2rem;
    margin-top: -1rem;
    font-size: 1.7rem;
  }

  .profileFullName {
    margin-top: 12px;
    margin-left: 0%;
    justify-content: center;
  }

  .profileFullName .MuiTypography-root {
    font-weight: 600;
    margin-top: 12px;
    font-size: 1rem;
  }

  .profileEmail {
    margin-top: -10px;
    margin-left: -50px;
  }

  .profileNum {
    margin-top: 5px;
    margin-left: -50px;
  }

  .profileEmailTypography.MuiTypography-root {
    font-size: 0.8rem;
    font-weight: 600;
    margin-left: -1px;
  }

  .profileNumTypography.MuiTypography-root {
    font-size: 0.8rem;
    font-weight: 600;
  }

  .profileImageContent {
    margin-left: 28%;
  }

  .IndustryUpdateMV {
    width: 248px;
  }

  .profileIconView.MuiSvgIcon-root {
    font-size: 1.1rem;
    margin-bottom: -3px;
  }

  .profileTopContent {
    display: block;
  }
  .basicDetailSizeTypography.MuiTypography-root {
    margin: 0; 
    font-family: "Roboto", "Helvetica", "Arial", sans-serif; 
    font-weight: 400; 
    font-size: 0.75rem; 
    line-height: 1.5; 
    letter-spacing: 0.00938em; 
  }
}

@media only screen and (min-width: 320px) {
  /* .JobsAlignment{
    display: grid;
    grid-template-columns: 45% 55%;
  } */
  .videoGrid{
    margin-top: 10px;
  }
  .ResumeName.MuiTypography-root{
    margin-left: 5px;
  }
  .profileImageContent {
    margin-left: 32%;
  }
  .FamilyAlert.MuiAlert-root {
    font-size: 0.8rem;
  }
  .profileTopContent {
    display: block;
  }

  .profileEmailTypography.MuiTypography-root {
    font-size: 0.8rem;
    font-weight: 600;
    margin-left: -1px;
  }

  .profileNumTypography.MuiTypography-root {
    font-size: 0.8rem;
    font-weight: 600;
  }

  .basicDetailSizeTypography.MuiTypography-root {
    margin: 0; 
    font-family: "Roboto", "Helvetica", "Arial", sans-serif; 
    font-weight: 400; 
    font-size: 0.75rem; 
    line-height: 1.5; 
    letter-spacing: 0.00938em; 
  }

  .searchInput.MuiTextField-root {
    width: 200px;
  }

  .JobDescriptionOverflow.MuiTypography-root {
    display: inline-block;
    width: 300px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  .jobImgLogo {
    margin-top: 10px;
    margin-left: 10px;
    width: 60px;
    height: 50px;
    border: 1px solid rgb(106 164 222);
  }

  .ApplyCard {
    position: relative;
  }

  .ApplyButton {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .jobValues {
    display: block !important;
  }

  .recommendedJobCard {
    grid-template-columns: repeat(1, 1fr);
  }

  .IndustryUpdateMV {
    width: 220px;
  }

  #hide {
    display: none;
    position: static;
  }

  #mobileView {
    display: block;
    z-index: 99;
  }

  .updateProfileImage {
    height: 60px;
    width: 60px;
    border-radius: 50px;
    border: 3px solid green;
  }

  .cameraicon.MuiSvgIcon-root {
    margin-left: 1.4rem;
    font-size: 1.4rem;
  }

  .CircularProgressIcon.MuiCircularProgress-root {
    margin-left: 1.2rem;
    margin-top: -1rem;
    font-size: 1.7rem;
  }

  .profileFullName .MuiTypography-root {
    margin-top: 12px;
    font-size: 1rem;
  }

  .profileEmail {
    margin-top: 3px;
    margin-left: -50px;
  }

  .profileNum {
    margin-top: 5px;
    margin-left: -50px;
  }
}

@media only screen and (min-width: 350px) {
  .JobsNameAlignment{
    display: flex;
    justify-content: space-between;
  }
  .JobsAlignment{
    display: grid;
    grid-template-columns: 40% 60%;
  }
  .Educationfieldsize{
    width: 230px;
  }
  .ResumeName.MuiTypography-root{
    margin-left: -5px;
  }
  .profileImageContent {
    margin-left: 34%;
  }
  .jobImgLogo {
    margin-top: 10px;
    margin-left: 10px;
    width: 70px;
    height: 50px;
    border: 1px solid rgb(106 164 222);
  }

  .ApplyCard {
    position: relative;
    /* height: 380px; */
  }

  .searchInput.MuiTextField-root {
    width: 240px;
  }

  .JobDescriptionOverflow.MuiTypography-root {
    display: inline-block;
    width: 300px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  .jobValues {
    display: block !important;
  }

  .recommendedJobCard {
    grid-template-columns: repeat(1, 1fr);
  }

  .profileFullName .MuiTypography-root {
    margin-top: 12px;
    font-size: 1.1rem;
  }

  .basicDetailSizeTypography.MuiTypography-root {
    margin: 0; 
    font-family: "Roboto", "Helvetica", "Arial", sans-serif; 
    font-weight: 400; 
    font-size: 0.75rem; 
    line-height: 1.5; 
    letter-spacing: 0.00938em; 
  }

  .IndustryUpdateMV {
    width: 278px;
  }

  #hide {
    display: none;
  }

  #mobileView {
    display: block;
    z-index: 99;
  }

  .updateProfileImage {
    height: 60px;
    width: 60px;
    border-radius: 50px;
    border: 3px solid green;
  }

  .cameraicon.MuiSvgIcon-root {
    margin-left: 1.35rem;
    font-size: 1.4rem;
  }

  .profileFullName {
    margin-top: 12px;
  }

  .profileEmail {
    margin-left: -50px;
  }

  .profileNum {
    margin-top: 5px;
    margin-left: -50px;
  }

  .CircularProgressIcon.MuiCircularProgress-root {
    margin-left: 1.2rem;
    margin-top: -1rem;
    font-size: 1.7rem;
  }
}

@media only screen and (min-width: 400px) {
  .JobsAlignment{
    display: grid;
    grid-template-columns: 30% 70%;
  }
  .Educationfieldsize{
    width: 280px;
  }
  .ResumeName.MuiTypography-root{
    margin-left: -22px;
  }
  .profileImageContent {
    margin-left: 38%;
  }
  .profileIconView.MuiSvgIcon-root {
    font-size: 1.3rem;
    margin-bottom: -6px;
  }
  .profileEmailTypography.MuiTypography-root {
    font-size: 1rem;
    margin-left: -3px;
  }

  .profileNumTypography.MuiTypography-root {
    font-size: 1rem;
  }

  .ApplyCard {
    position: relative;
    /* height: 350px; */
  }

  .jobImgLogo {
    margin-top: -2px;
    margin-left: 10px;
    width: 70px;
    height: 50px;
    border: 1px solid rgb(106 164 222);
  }

  .searchInput.MuiTextField-root {
    width: 270px;
  }

  .jobValues {
    display: block !important;
  }

  .recommendedJobCard {
    grid-template-columns: repeat(1, 1fr);
  }

  .profileFullName .MuiTypography-root {
    margin-top: 12px;
    font-size: 1.5rem;
  }

  .basicDetailSizeTypography.MuiTypography-root {
    margin: 0; 
    font-family: "Roboto", "Helvetica", "Arial", sans-serif; 
    font-weight: 400; 
    font-size: 0.9rem; 
    line-height: 1.5; 
    letter-spacing: 0.00938em; 
  }

  .IndustryUpdateMV {
    width: 302px;
  }

  #hide {
    display: none;
  }

  #mobileView {
    display: block;
    z-index: 99;
  }

  .updateProfileImage {
    height: 60px;
    width: 60px;
    border-radius: 50px;
    border: 3px solid green;
  }

  .cameraicon.MuiSvgIcon-root {
    margin-left: 1.35rem;
    font-size: 1.4rem;
  }

  .CircularProgressIcon.MuiCircularProgress-root {
    margin-left: 1.2rem;
    margin-top: -1rem;
    font-size: 1.7rem;
  }
}

@media only screen and (min-width: 540px) {
  .Educationfieldsize{
    width: 400px;
  }
  .ResumeName.MuiTypography-root{
    margin-left: -55px;
  }
  .FamilyAlert.MuiAlert-root {
    font-size: 1rem;
  }
  .profileFullName {
    margin-top: 12px;
    margin-left: 0%;
    justify-content: left;
  }

  .profileTopContent {
    display: flex;
  }

  .profileEmail {
    margin-top: 35px;
    margin-left: -114px;
  }

  .profileImageContent {
    margin-left: 0%;
  }

  .profileNum {
    margin-top: 5px;
    margin-left: -114px;
  }

  .profileFullName .MuiTypography-root {
    margin-top: 12px;
    font-size: 1.5rem;
  }

  .IndustryUpdateMV {
    width: 448px;
  }
}

@media only screen and (min-width: 600px) {
  .ResumeName.MuiTypography-root{
    margin-left: 5px;
  }
  .searchInput.MuiTextField-root {
    width: 450px;
  }

  .profileEmailTypography.MuiTypography-root {
    font-size: 1rem;
    margin-left: 2px;
  }

  .jobImgLogo {
    margin-top: 2px;

    width: 100px;
    height: 75px;
    border: 1px solid rgb(106 164 222);
  }

  .ApplyCard {
    position: relative;
  }

  .ApplyButton {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .basicDetailSizeTypography.MuiTypography-root {
    margin: 0; 
    font-family: "Roboto", "Helvetica", "Arial", sans-serif; 
    font-weight: 400; 
    font-size: 1rem; 
    line-height: 1.5; 
    letter-spacing: 0.00938em; 
  }

  .JobDescriptionOverflow.MuiTypography-root {
    display: inline-block;
    width: 500px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  .jobValues {
    display: flex !important;
    justify-content: space-between;
    width: 100%;
  }

  .recommendedJobCard {
    grid-template-columns: repeat(1, 1fr);
  }

  .updateProfileImage {
    height: 60px;
    width: 60px;
    border-radius: 50px;
    border: 3px solid green;
  }
}

@media only screen and (min-width: 768px) {
  .Educationfieldsize{
    width: 400px;
  }
  .ResumeName.MuiTypography-root{
    margin-left: -10px;
  }
  .profileEmail {
    margin-top: 35px;
    margin-left: -114px;
  }

  .profileNum {
    margin-top: 5px;
    margin-left: -114px;
  }

  .jobImgLogo {
    margin-top: 2px;
    width: 100px;
    height: 75px;
    border: 1px solid rgb(106 164 222);
  }

  .searchInput.MuiTextField-root {
    width: 470px;
  }

  .IndustryUpdateMV {
    width: 552px;
  }

  .updateProfileImage {
    height: 60px;
    width: 60px;
    border-radius: 50px;
    border: 3px solid green;
  }

  .profileImageContent {
    margin-left: 0%;
  }
}

@media only screen and (min-width: 912px) {
  .ORTypo.MuiTypography-root{
    margin-top: 6rem;
  }
  .Educationfieldsize{
    width: 400px;
  }
  .ResumeName.MuiTypography-root{
    margin-left: 20px;
  }
  .searchInput.MuiTextField-root {
    width: 740px;
  }
  .profileIconView.MuiSvgIcon-root {
    font-size: 1.5rem;
  }
  .profileImageContent {
    margin-left: 0%;
  }

  .recommendedJobCard {
    grid-template-columns: repeat(2, 1fr);
  }

  #hide {
    display: block;
    height: 28rem;
    position: sticky;
    top: 0;
  }

  #mobileView {
    display: none;
    z-index: 99;
  }

 

  .avator1 {
    flex-wrap: nowrap;
  }

  .profile {
    flex-wrap: nowrap;
  }

  .updateProfileImage {
    height: 60px;
    width: 60px;
    border-radius: 50px;
    border: 3px solid green;
  }

  .cameraicon.MuiSvgIcon-root {
    margin-left: 1.4rem;
    margin-top: -1rem;
    font-size: 1.4rem;
  }

  .CircularProgressIcon.MuiCircularProgress-root {
    margin-left: 1.2rem;
    margin-top: -1rem;
    font-size: 1.7rem;
  }

  .IndustryUpdateMV {
    width: 552px;
  }

  .profileFullName .MuiTypography-root {
    margin-top: 12px;
    margin-left: -1rem;
    font-size: 1.5rem;
  }

  .profileEmail {
    margin-top: 35px;
    margin-left: -114px;
  }

  .profileNum {
    margin-top: 5px;
    margin-left: -114px;
  }
}

@media only screen and (min-width: 1200px) {
  .basicDetailSize{
    margin-top: 10px;
    margin-left: 10px;
  }
  .ORTypo.MuiTypography-root{
    margin-top: 6rem;
  }
  .Educationfieldsize{
    width: 400px;
  }
  .ResumeName.MuiTypography-root{
    margin-left: -7px;
  }
  .searchInput.MuiTextField-root {
    width: 1050px;
  }

  .FamilyAlert.MuiAlert-root {
    font-size: 1.1rem;
  }

  .recommendedJobCard {
    grid-template-columns: repeat(2, 1fr);
  }

  #hide {
    display: block;
    height: 27rem;
  }

  .IndustryUpdateMV {
    width: 552px;
  }

  #basicDetailGrid {
    display: flex;
  }

  .avator1 {
    display: flex;
  }

  .profile {
    display: flex;
  }

  .updateProfileImage {
    height: 80px;
    width: 80px;
    border-radius: 50px;
    border: 3px solid green;
  }

  .cameraicon.MuiSvgIcon-root {
    margin-left: 1.8rem;
    margin-top: -1rem;
    font-size: 1.7rem;
  }

  .CircularProgressIcon.MuiCircularProgress-root {
    margin-left: 1.8rem;
    margin-top: -1rem;
    font-size: 1.7rem;
  }

  .profileEmail {
    margin-top: 0px;
    margin-left: -10px;
  }

  .profileNum {
    margin-top: 0px;
    margin-left: 0px;
  }

  .profileFullName {
    margin-left: 14.4px;
  }

  .profileFullName .MuiTypography-root {
    margin-top: 12px;
    margin-left: 0.1rem;
    font-size: 1.5rem;
  }
}

.scrollable-container {
  max-height: 180px;
  overflow-y: scroll;
}
/* Hide scrollbar for heights less than 180px */
@media (max-height: 180px) {
  .scrollable-container::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
  }
}
/* Show scrollbar for heights more than 180px */
@media (min-height: 181px) {
  .scrollable-container::-webkit-scrollbar {
    width: 0.4em;
    background-color: #f1f1f1;
  }
  .scrollable-container::-webkit-scrollbar-thumb {
    background-color:rgb(183 221 232);
  }
}


/* for volunteer */

.scrollable-container-volunteer {
  max-height: 180px;
  overflow-y: scroll;
}
/* Hide scrollbar for heights less than 180px */
@media (max-height: 180px) {
  .scrollable-container-volunteer::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
  }
}
/* Show scrollbar for heights more than 180px */
@media (min-height: 181px) {
  .scrollable-container-volunteer::-webkit-scrollbar {
    width: 0.4em;
    background-color:  #f1f1f1;
  }
  .scrollable-container-volunteer::-webkit-scrollbar-thumb {
    background-color:rgb(53, 52, 52);
  }
}


/* LocalJob */

.LocalJobProfile{
  height: 100%;
    width: 100%;
}

.ImageBorder{
  border: 3px dashed #cbd5e0;
  background-color: rgba(166, 199, 212, 0.1);
  width: 100%;
  min-width: 100px;
  min-height: 100px;
  margin-top: 10px;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ImageOuter{
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ImageShowRow{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 10px;
}


