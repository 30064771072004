.imgGrid {
  position: sticky;
}


.fileSize {
  color: rgb(0, 255, 76);
}

.container {
  display: flex;
}

.resumefile {
  display: none;
}

.fileNameSize.MuiTypography-root {
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-top: 8px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 3px;
  border: none;
  display: inline-block;
  width: 200px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.iconsize {
  color: rgb(0, 255, 76);
}

.fileuploadcenter {
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: center;
  padding-top: 15px;
}

.fileName {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.qualification {
  display: flex;
  cursor: pointer;
}

.active5 {
  max-width: 300px;
  min-width: 100px;
  color: rgb(0, 195, 255);
  border: 2px solid rgb(0, 195, 255);
  padding-bottom: 5px;
  border-radius: 20px 20px 20px 20px;
}

.space {
  padding: 5px;
}

#mobile_number::-webkit-inner-spin-button,
#mobile_number::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

#mobile_number {
  -moz-appearance: textfield;
}

#pincode::-webkit-inner-spin-button,
#mobile_number::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

#pincode {
  -moz-appearance: textfield;
}

/* Important box */

.importantBox {
  border-radius: 5px;
  padding: 25px 0px;
  margin: 0;
  border: 1px solid #bdbdbd;
}
.importantBox_for_local {
  border-radius: 4px;
  padding: 25px 0px;
  margin: 0;
  border: 2px solid #666666;
}
.importantBox_for_local legend {
  /* color: #1455e0;
  font-style: italic; */
  margin-left: 20px;
}
.importantBox1 {
  border-radius: 5px;

  margin: 0;
  border: 1px solid #bdbdbd;
}

.importantBox legend {
  color: #666666;
  font-style: italic;
  margin-left: 7px;
  font-weight: bold;
}

.importantBox1 legend {
  color: #5d5d5f;
  font-style: italic;
  margin-left: 7px;
}

/* upload box */

.side-tamil {
  font-size: 12px !important;
}
.side-tamil-1 {
  font-size: 15px !important;
}
.side-english {
  font-size: 1rem !important;
}
.sidereg-tamil {
  position: fixed;
  width: 250px;
  height: 100%;
  margin-top: 13vh;
  font-size: 11px;
}

.uploadbox {
  padding: 3px 10px;
}

.uploadbox p {
  padding: 0;
  margin: 0;
  color: #7a7979;
}

.sideReg {
  position: fixed;
  width: 250px;
  height: 100%;
  margin-top: 13vh;
}

.sideReg .logo1 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.logo {
  width: 100%;
}

/* important box over */

/* media query */

@media only screen and (min-width: 250px) {
  #ContactDetailsFieldset {
    width: 200px;
  }

  .ContactDetailsFormControl.MuiFormControl-root {
    width: 160px;
  }

  .ContactDetailsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .example.MuiTypography-root {
    font-size: 1rem;
  }

  .secondGrid {
    padding-left: 45px;
    padding-right: 45px;
  }

  .fileUpload.MuiButton-root {
    padding: 5px;
    font-size: 8px;
  }

  .iconsize.MuiSvgIcon-root {
    font-size: 1rem;
    margin-top: 6px;
  }

  .Box {
    flex-wrap: wrap;
  }

  .qualification {
    width: 120px;
  }

  .qualificationName.MuiTypography-root {
    font-size: 0.9rem;
  }
  .qualificationName1.MuiTypography-root {
    font-size: 0.8rem;
  }

  .sideReg {
    display: none;
  }
}

@media only screen and (min-width: 300px) {
  #ContactDetailsFieldset {
    width: 250px;
  }

  .ContactDetailsFormControl.MuiFormControl-root {
    width: 200px;
  }

  .ContactDetailsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .example.MuiTypography-root {
    font-size: 1rem;
  }
  .fileUpload.MuiButton-root {
    padding: 5px;
    font-size: 8px;
  }

  .fileName .fileNameSize {
    margin-top: -10px;
    font-size: x-small;
  }

  .iconsize.MuiSvgIcon-root {
    font-size: 1rem;
    margin-top: -5px;
  }

  .Box {
    flex-wrap: wrap;
  }

  .qualification {
    width: 160px;
  }

  .qualificationName.MuiTypography-root {
    font-size: 1.2rem;
  }
  .qualificationName1.MuiTypography-root {
    font-size: 1rem;
  }

  .sideReg {
    display: none;
  }
}

@media only screen and (min-width: 400px) {
  .ContactDetailsFormControl.MuiFormControl-root {
    width: 100%;
  }

  .ContactDetailsContainer {
    width: 100%;
  }

  #ContactDetailsFieldset {
    width: 100%;
    padding: 25px;
  }

  .secondGrid {
    padding-left: 30px;
    padding-right: 30px;
  }
  .example.MuiTypography-root {
    font-size: large;
  }

  .fileUpload.MuiButton-root {
    padding: 5px;
    font-size: 10px;
  }

  .fileName .fileNameSize {
    font-size: small;
  }

  .iconsize.MuiSvgIcon-root {
    font-size: 1.3rem !important;
  }

  .Box {
    flex-wrap: wrap;
  }

  .qualification {
    width: 200px;
  }

  .sideReg {
    display: none;
  }
}

@media only screen and (min-width: 600px) {
  .example.MuiTypography-root {
    font-size: x-large;
  }

  .fileUpload.MuiButton-root {
    padding: 8px;
    font-size: 14px;
  }

  .fileName .fileNameSize {
    font-size: medium;
  }

  .iconsize.MuiSvgIcon-root {
    font-size: 1.5rem !important;
  }

  .Box {
    flex-wrap: nowrap;
  }

  .qualification {
    width: 200px;
  }

  .sideReg {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .secondGrid {
    padding-left: 65px;
    padding-right: 65px;
  }
  .example {
    font-size: large;
  }

  .fileName .fileNameSize {
    font-size: medium;
  }

  .Box {
    flex-wrap: nowrap;
  }

  .qualification {
    width: 160px;
  }

  .qualificationName.MuiTypography-root {
    font-size: 1.1rem;
  }
  .qualificationName1.MuiTypography-root {
    font-size: 1rem;
  }

  .sideReg {
    display: none;
  }
}

@media only screen and (min-width: 912px) {
  .secondGrid {
    padding-left: 120px;
    padding-right: 120px;
  }
  .example {
    font-size: larger;
  }

  .fileName .fileNameSize {
    font-size: medium;
  }

  .Box {
    flex-wrap: nowrap;
  }

  .qualification {
    width: 180px;
  }

  .qualificationName.MuiTypography-root {
    font-size: 1.3rem;
  }
  .qualificationName1.MuiTypography-root {
    font-size: 1.1rem;
  }

  .sideReg {
    position: fixed;
    display: block;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 100%;
  }
}

@media only screen and (min-width: 1200px) {
  .example {
    font-size: larger;
  }

  .fileName .fileNameSize {
    font-size: larger;
  }

  .Box {
    flex-wrap: nowrap;
  }

  .qualification {
    width: 250;
  }
}

/* =============== New job seeker form =============== */

.dailog{
  z-index: 99999 !important;
}


.overall-jobseeker-form {
  display: grid;
  grid-template-columns: 39% 61%;
}
 /* .left-jobseekers-img{
  width: 20px;
  position: fixed;
  z-index: 1;
  top: 20px;
  left: 10px;
  background: #eee;
  overflow-x: hidden;
  padding: 8px 0;
}  */
.leftimg-box{
  width: 450px;
  position: fixed;
  z-index: 1;
}

.leftimg-box img {
  width: 100%;
}
/* .left-jobseekers-img{
  display: grid;
  place-items: center;
} */
.jobseeker-form-box {
  padding: 20px;
  padding-bottom: 0px;
}
.jobseeker-form-header {
  display: flex;
  justify-content: center;
}
.jobseeker-form-header h2 {
  color: #005e82;
  font-size: 20px;
  font-family: "Poppins-SemiBold";
  position: relative;
}
.jobseeker-form-header h2 span {
  color: #ec2172;
  font-family: "Poppins-SemiBold";
}
.jobseeker-form-header h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 38px;
  height: 2px;
  background: #d36b95;
  left: 0;
  right: 0;
  bottom: -10px;
  margin: auto;
}
.jobseeker-box-inputs {
 
  width: 450px;
  border-radius: 25px;
  background: rgba(16, 143, 178, 0.1);
  padding: 40px;
  box-shadow: 0px 0px 30px 3px rgba(16, 143, 178, 0.3);
 
  display: flex;
  flex-direction: column;
  gap: 0px;
}


.jobseeker-box-reg-form {
  display: flex;
  justify-content: center;
}
.jobseeker-textfield label {
  padding: 10px 0px;
  padding-top: 0px;
  font-size: 16px;
  font-family: "Poppins-Medium";
}
.jobseeker-textfield label text{
  color: #e40808f8;
}

.jobseeker-textfield input::placeholder {
  font-size: small;
  font-family: "Poppins-Regular";
}
.jobseeker-btn-img {
  margin-top: 8px;
}
.jobseeker-btn-img img {
  width: 24px;
}
.jobseeker-btn-img .girlimg {
  width: 19px;
}
.Industryimg {
  width: 20px;
  margin-left: 10px;
}
.gendericon {
  color: #108fb2;
}
.gendericon-Active {
  color: #ffffff;
}
.btn-space-name {
  margin-left: 6px;
  font-family: "Poppins-Regular";
  text-transform: capitalize;
  color: #108fb2;
  font-weight: 500;
  font-size: 14px;
  font-style: normal;
}
.btn-space-name-active {
  margin-left: 6px;
  font-family: "Poppins-Regular";
  text-transform: capitalize;
  color: #ffffff !important;
  font-weight: 500;
  font-size: 14px;
  font-style: normal;
}
.btn-space-name-1-active {
  font-family: "Poppins-Regular";
  text-transform: capitalize;
  color: #ffffff !important;
  font-weight: 500;
  font-size: 14px;
  font-style: normal;
}
.btn-space-name-1 {
  font-family: "Poppins-Regular";
  text-transform: capitalize;
  color: #108fb2;
  font-weight: 500;
  font-size: 14px;
  font-style: normal;
}
.jobseeker-btn-gender {
  border-radius: 6px !important;
  background-color: #ffffff !important;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.1) !important;
  border: 1px solid transparent !important;
  margin-left: 15px !important;
}
.jobseeker-btn-gender-active {
  border-radius: 6px !important;
  background-color: #108fb2 !important;
  box-shadow: 2px 2px 2px 1px rgba(16, 143, 178, 0.25);
  color: #fff !important;
  border: 1px solid transparent !important;
  margin-left: 15px !important;
}
.jobseeker-box-voitingdetails .header-voting {
  font-family: "Poppins-SemiBold";
  display: flex;
  justify-content: flex-start;
  font-size: 17px;
  font-style: normal;
  padding-bottom: 10px;
  padding-top: 5px;
}
.jobseeker-box-voitingdetails {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}
.upload-jobseeker-regform {
  background-color: #ec2172 !important ;
  transition: 0.3s !important;
}
.upload-jobseeker-regform:hover {
  opacity: 0.8;
}
.uploade-note {
  color: #777;
  font-family: "Poppins-Regular";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  margin-top: 10px;
}
.uploade-note-1 {
  color: #777;
  font-family: "Poppins-Regular";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 3px;
}
.Work_Status {
  font-family: "Poppins-Regular";
  color: #444;
  font-style: normal;
  font-weight: 400;
  font-size: small;
  margin-bottom: 5px;
}

.terms-and-conditions {
  color: #108fb2;
  font-family: "Poppins-Regular";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-top: 4px;
  cursor: pointer;
}
.terms-and-conditions-1 {
  color: #ec2172;
  font-family: "Poppins-Regular";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-top: 4px;
}
.terms-and-conditions:hover {
  text-decoration: underline;
}
.uploadfilenames-and-removefilebtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.removefile-btn {
  border-radius: 6px !important;
  border: 1px solid #444 !important;
  background-color: transparent !important;
  color: #444 !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
  font-family: "Poppins-Regular" !important;
}
.filename-uploadresume {
  color: #444;
  font-family: "Poppins-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.filename-uploadresume-error {
  color: #ff3434;
  font-family: "Poppins-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.img-complete img {
  width: 274.119px;
  height: 300.27px;
  flex-shrink: 0;
}
.img-complete {
  display: grid;
  place-items: center;
}

.Successfully-text {
  margin: 0;
  color: #222;
  font-family: "Poppins-Medium";
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}
.Check-text {
  color: #444;
  text-align: center;
  font-family: "Poppins-Medium";
  font-size: 13px;
  font-style: normal;
  line-height: normal;
  margin-top: -10px !important;
}
.apply-your-text {
  text-align: center;
  margin: 0;
  color: #666;

  font-family: "Poppins-Regular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.set-widthfordailogbox {
  width: 800px;
}
.btn-successfully {
  border-radius: 9px !important;
  background: var(--Rose, #ec2172) !important;
  color: #fff !important;
  font-family: "Poppins-Medium" !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  text-transform: capitalize !important;
  padding: 10px 20px !important;
  margin-top: 10px !important;
}
.ahjahg {
  display: grid;
  place-items: center;
}

@media only screen and (max-width: 1280px) {
  .overall-jobseeker-form {
    display: grid;
    grid-template-columns: 52% 48%;
  }
}

@media only screen and (max-width: 1024px) {
  .overall-jobseeker-form {
    display: grid;
    grid-template-columns: 52% 48%;
  }
  .jobseeker-box-inputs {
    
    width: 450px;
    padding: 20px 30px;
    border-radius: 5px;
  }
}

@media screen and (max-width: 375px) {
  .overall-jobseeker-form {
    display: grid;
    grid-template-columns: 100%;
  }
  .left-jobseekers-img {
    display: none;
  }
  .Successfully-text{
       margin-top: 50px;
   }
  .jobseeker-box-inputs {
    
    width: 100%;
    padding: 20px;
  }
  .set-widthfordailogbox {
    width: 100%;
  }
  .mobile-for-gender {
    display: flex !important;
    gap: 20px !important;
    flex-direction: column !important;
  }
  .mobile-for-Work-Status {
    display: flex !important;
    gap: 20px !important;
    flex-direction: column !important;
  }
}

@media screen and (max-width: 767px) {
  .set-widthfordailogbox {
    width: 100%;
  }
  .overall-jobseeker-form {
    display: grid;
    grid-template-columns: 100%;
  }
  
  .left-jobseekers-img {
    display: none;
  }
  .jobseeker-box-inputs {
    
    width: 100%;
    padding: 20px;
    border-radius: 5px;
  }
  .mobile-for-gender {
    display: flex !important;
    gap: 20px !important;
    flex-direction: column !important;
  }
  .mobile-for-Work-Status {
    display: flex !important;
    gap: 20px !important;
    flex-direction: column !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .overall-jobseeker-form {
    display: grid;
    grid-template-columns: 100%;
  }
  .set-widthfordailogbox {
    width: 100%;
  }
  
  .left-jobseekers-img {
    display: none;
  }
  .jobseeker-box-inputs {
    border-radius: 5px;
    width: 100%;
    padding: 20px;
  }
  .mobile-for-gender {
    display: flex !important;
    gap: 20px !important;
    flex-direction: column !important;
  }
  .mobile-for-Work-Status {
    display: flex !important;
    gap: 20px !important;
    flex-direction: column !important;
  }
}

@media only screen and (width: 1024px) and (height: 1366px) {
  .overall-jobseeker-form {
    display: grid;
    grid-template-columns: 100%;
  }
  .jobseeker-box-inputs {
   
    width: 100%;
    padding: 20px;
    border-radius: 5px;
  }
  .left-jobseekers-img {
    display: none;
  }
  
  .mobile-for-gender {
    display: flex !important;
    gap: 20px !important;
    flex-direction: column !important;
  }
  .mobile-for-Work-Status {
    display: flex !important;
    gap: 20px !important;
    flex-direction: column !important;
  }
}



