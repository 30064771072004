.Postimg-grid {
  position: sticky;
}
.Postsideimg {
  position: fixed;
  width: 250px;
  height: 100%;
  margin-top: 13vh;
}

.Postsideimglogo {
  display: flex;
  justify-content: start;
}

.Postlistofsidecontect {
  display: flex;
  gap: 5px;
}
.ddd {
  width: 100% !important ;
}
.Postsidecontect {
  width: 100%;
}

.cards {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  gap: 5px;
  padding-bottom: 20px;
  background-color: #a8cfdf;
}
.main-div {
  max-width: 1200px;
  background-color: #a8cfdf;
}
.pagination {
  display: flex;
  max-width: 1200px;
  text-align: center;
  align-items: center;
  justify-content: end;
  padding: 10px 25px 25px 0px;
  gap: 10px;
}
.car {
  margin: 20px 10px 0px 10px;
  color: white;
  padding: 1rem;
  height: auto;
}
.jobDetails {
  display: grid;
  grid-template-columns: 40% 55%;
  gap: 10px;
}
span.font {
  font-weight: bold;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.main-cards {
  display: grid;
  grid-template-columns: 20% 20% 20% 20%;
  gap: 20px;
  padding: 10px 23px 23px 25px;
  place-items: center;
}
.link-active-paper {
  height: 120px;
  width: 90%;
  border-radius: 25px;
  transition: 0.5s;
  cursor: pointer;
}

.active-paper {
  height: 120px;
  width: 90%;
  border-radius: 25px;
  border: 1px solid;

  transform: scale(1.1);
  box-shadow: 0px 0px 8px 2px rgba(16, 143, 178, 1);
  transition: 0.5s;
  cursor: pointer;
}
#heading {
  text-align: center;
  margin-top: 10px;
  margin-right: 5px;
  font-size: 30px;
}
#headingSpan {
  margin-right: 5px;
}
#heading1 {
  text-align: center;
  font-size: 18px;
}

@media (min-width: 600px) {
  .cards {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (min-width: 900px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 600px) {
  .main-cards {
    grid-template-columns: repeat(2, 1fr);
  }
  .link-active-paper {
    height: 120px;
    width: 90%;
    border-radius: 25px;
    transition: 0.5s;
    cursor: pointer;
  }
  .active-paper {
    height: 120px;
    width: 90%;
    border-radius: 25px;
    border: 1px solid;

    transform: scale(1.1);
    box-shadow: 0px 0px 8px 2px rgba(16, 143, 178, 1);
    transition: 0.5s;
    cursor: pointer;
  }
}
@media (min-width: 300px) {
  .main-cards {
    grid-template-columns: repeat(2, 1fr);
  }
  .link-active-paper {
    height: 60px;
    width: 90%;
    border-radius: 20px;
    transition: 0.5s;
    cursor: pointer;
    font-size: 15px;
  }
  .active-paper {
    height: 60px;
    width: 90%;
    border-radius: 20px;
    border: 1px solid;

    transform: scale(1.1);
    box-shadow: 0px 0px 8px 2px rgba(16, 143, 178, 1);
    transition: 0.5s;
    cursor: pointer;
  }
  #heading {
    text-align: center;
    margin-top: 10px;
    margin-right: 5px;
    font-size: 18px;
  }
  #headingSpan {
    margin-right: 5px;
    font-size: 10px;
  }
  #heading1 {
    text-align: center;
    font-size: 12px;
  }
}
@media (min-width: 200px) {
  .main-cards {
    grid-template-columns: repeat(2, 1fr);
  }
  .link-active-paper {
    height: 60px;
    width: 90%;
    border-radius: 20px;
    transition: 0.5s;
    cursor: pointer;
    font-size: 15px;
  }
  .active-paper {
    height: 60px;
    width: 90%;
    border-radius: 20px;
    border: 1px solid;

    transform: scale(1.1);
    box-shadow: 0px 0px 8px 2px rgba(16, 143, 178, 1);
    transition: 0.5s;
    cursor: pointer;
  }
  #heading {
    text-align: center;
    margin-top: 10px;
    margin-right: 5px;
    font-size: 18px;
  }
  #headingSpan {
    margin-right: 5px;
    font-size: 10px;
  }
  #heading1 {
    text-align: center;
    font-size: 13px;
  }
}
@media (min-width: 900px) {
  .main-cards {
    grid-template-columns: repeat(4, 1fr);
  }
  .link-active-paper {
    height: 120px;
    width: 90%;
    border-radius: 25px;
    transition: 0.5s;
    cursor: pointer;
  }

  .active-paper {
    height: 120px;
    width: 90%;
    border-radius: 25px;
    border: 1px solid;

    transform: scale(1.1);
    box-shadow: 0px 0px 8px 2px rgba(16, 143, 178, 1);
    transition: 0.5s;
    cursor: pointer;
  }
  #heading {
    text-align: center;
    margin-top: 20px;
    margin-right: 5px;
    font-size: 30px;
  }
  #headingSpan {
    margin-right: 5px;
  }
  #heading1 {
    text-align: center;
    font-size: 18px;
  }
}

@media only screen and (min-width: 250px) {
  .Box {
    flex-wrap: wrap;
  }
  .Postsideimg {
    display: none;
  }
}

@media only screen and (min-width: 300px) {
  .Box {
    flex-wrap: wrap;
  }

  .Postsideimg {
    display: none;
  }
}

@media only screen and (min-width: 400px) {
  .Box {
    flex-wrap: wrap;
  }

  .Postsideimg {
    display: none;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .Box {
    flex-wrap: nowrap;
  }

  .Postsideimg {
    display: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .Box {
    flex-wrap: nowrap;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 912px) {
  .Postsideimg {
    position: fixed;
    display: block;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 100%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .Box {
    flex-wrap: nowrap;
  }
}

.arrow-btn {
  margin-top: 12px;
  justify-content: center;
}
.arrow-btn2 {
  margin-top: 12px;
  margin-bottom: 12px;
  justify-content: center;
}
.filter-label {
  margin-top: 12px;
  margin-bottom: 12px;
  justify-content: center;
}
.arrow-link-post {
  top: 0;
  background-color: #ffffff;
  z-index: 9;
  width: 50%;
  border: 0px 2px 2px 2px solid #ffffff;
  position: sticky;
}
.arrow-link {
  top: 0;
  background-color: #ffffff;
  z-index: 9;
  border: 0px 2px 2px 2px solid #ffffff;
  position: sticky;
}
#headingSeekers {
  font-size: 30px;
  font-weight: 600;
  margin-top: 15px;
}

@media only screen and (max-width: 900px) {
  #headingSeekers {
    margin-top: 20px;
    font-size: 23px;
    font-weight: 600;
  }
}
@media only screen and (max-width: 560px) {
  #headingSeekers {
    margin-top: 25px;
    font-size: 16px;
    font-weight: 600;
  }
}
@media only screen and (max-width: 500px) {
  #headingSeekers {
    margin-top: 30px;
    font-size: 12px;
    font-weight: 600;
  }
}

@media only screen and (max-width: 450px) {
  .headingPart {
    display: grid !important;
    grid-template-columns: 15% 80% !important;
    width: 100%;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  #headingSeekers {
    margin-top: 25px;
    font-size: 18px;
    font-weight: 600;
  }
  #backIcon {
    margin-bottom: 20px;
  }
  .filter {
    margin-left: 100px;
  }
}
@media only screen and (max-width: 400px) {
  .headingPart {
    display: grid !important;
    grid-template-columns: 18% 80% !important;
    width: 100%;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  #headingSeekers {
    margin-top: 25px;
    font-size: 18px;
    font-weight: 600;
  }
  #backIcon {
    margin-bottom: 20px;
  }
  .filter {
    margin-left: 70px;
  }
}
.headingPart {
  display: flex;
  width: 100%;
  /* margin-top: 12px;
  margin-bottom: 12px; */
  justify-content: space-between;
}
#postHeading {
  font-size: 30px;
  font-weight: 600;
  margin-top: 10px;
}
#postHeadingdatabase {
  font-size: 30px;
  font-weight: 600;
  margin-top: 10px;
  margin-right: 10px;
}
#apSeeHeading {
  font-size: 30px;
  font-weight: 600;
  margin-top: 10px;
  margin-left: -40%;
}
@media only screen and (min-width: 900px) {
  .appliedMainCard {
    display: grid;
    grid-template-columns: 16% 16% 16% 16% 16% 16%;
    gap: 8px;
    padding: 0px 23px 10px 25px;
    place-items: center;
  }
  .appliedMainCard1 {
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    gap: 8px;
    padding: 0px 23px 10px 25px;
    place-items: center;
  }
  .AppliedDashboard {
    height: 120px;
    width: 90%;
    border-radius: 25px;
    transition: 0.5s;
    cursor: pointer;
    opacity: 0.7;
  }
  .activeAppliedDashboard {
    height: 120px;
    width: 90%;
    border-radius: 25px;
    transition: 0.2s;
    cursor: pointer;
    border: 2px solid black;
    box-shadow: 2px 2px 2px 2px rgba(3, 1, 1, 0.5);
    opacity: 1;
  }
  #heading1 {
    text-align: center;
    font-size: 18px;
  }
  #heading {
    font-size: 30px;
  }
  .Appliedseekericonsize.MuiSvgIcon-root {
    font-size: 25px;
  }
}

@media only screen and (max-width: 900px) {
  #postHeading {
    font-size: 30px;
    font-weight: 600;
    margin-top: 10px;
    margin-left: -80px;
  }
  .AppliedDashboard {
    height: 75px;
    width: 90%;
    border-radius: 25px;
    transition: 0.5s;
    cursor: pointer;
    opacity: 0.7;
  }
  .activeAppliedDashboard {
    height: 75px;
    width: 90%;
    border-radius: 25px;
    transition: 0.2s;
    cursor: pointer;
    border: 2px solid black;
    box-shadow: 2px 2px 2px 2px rgba(3, 1, 1, 0.5);
    opacity: 1;
  }
  #heading1 {
    text-align: center;
    font-size: 14px;
  }
  #heading {
    font-size: 16px;
  }
  .Appliedseekericonsize.MuiSvgIcon-root {
    font-size: 20px;
  }
}
@media only screen and (max-width: 900px) {
  #postHeadingdatabase {
    font-size: 30px;
    font-weight: 600;
    margin-top: 10px;
    margin-left: -80px;
  }
}
@media only screen and (max-width: 900px) {
  #apSeeHeading {
    font-size: 25px;
    font-weight: 600;
    margin-top: 14px;
    margin-left: -50%;
  }
  .appliedMainCard {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    gap: 8px;
    padding: 0px 23px 10px 25px;
    place-items: center;
  }
  .appliedMainCard1 {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    gap: 8px;
    padding: 0px 23px 10px 25px;
    place-items: center;
  }
}
@media only screen and (max-width: 450px) {
  #apSeeHeading {
    font-size: 20px;
    font-weight: 600;
    margin-top: 19px;
    margin-left: -80%;
  }
  .appliedMainCard {
    margin-top: -20px;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    gap: 8px;
    padding: 5px 23px 10px 25px;
    place-items: center;
  }
  .appliedMainCard1 {
    margin-top: -20px;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    gap: 8px;
    padding: 5px 23px 10px 25px;
    place-items: center;
  }
  .AppliedDashboard {
    height: 85px;
    width: 90%;
    border-radius: 25px;
    transition: 0.5s;
    cursor: pointer;
    opacity: 0.7;
  }
  .activeAppliedDashboard {
    height: 80px;
    width: 90%;
    border-radius: 25px;
    transition: 0.2s;
    cursor: pointer;
    border: 2px solid black;
    box-shadow: 2px 2px 2px 2px rgba(3, 1, 1, 0.5);
    opacity: 1;
  }
  #heading1 {
    text-align: center;
    font-size: 14px;
  }
  #heading {
    font-size: 16px;
  }
  .Appliedseekericonsize.MuiSvgIcon-root {
    font-size: 19px;
  }
}
.appliedViewProfile {
  display: flex;
}
.viewProfileHead {
  margin-top: 20px;
  margin-left: 15px;
  text-align: center;
  font-size: 23px;
}
@media only screen and (max-width: 400px) {
  .appliedViewProfile {
    display: block;
  }
  .viewProfileHead {
    margin-top: 5px;
    margin-left: 15px;
    text-align: center;
    font-size: 23px;
  }
  .appliedMainCard {
    margin-top: -17px;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    gap: 8px;
    padding: 5px 23px 10px 25px;
    place-items: center;
  }
  .appliedMainCard1 {
    margin-top: -17px;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    gap: 8px;
    padding: 5px 23px 10px 25px;
    place-items: center;
  }
  .AppliedDashboard {
    height: 80px;
    width: 90%;
    border-radius: 25px;
    transition: 0.5s;
    cursor: pointer;
    opacity: 0.7;
  }
  .activeAppliedDashboard {
    height: 80px;
    width: 90%;
    border-radius: 25px;
    transition: 0.2s;
    cursor: pointer;
    border: 2px solid black;
    box-shadow: 2px 2px 2px 2px rgba(3, 1, 1, 0.5);
    opacity: 1;
  }
  #heading1 {
    text-align: center;
    font-size: 12px;
  }
  #heading {
    font-size: 16px;
  }
  .Appliedseekericonsize.MuiSvgIcon-root {
    font-size: 18px;
  }
}
@media only screen and (max-width: 350px) {
  .appliedMainCard {
    margin-top: -20px;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    gap: 8px;
    padding: 5px 23px 10px 25px;
    place-items: center;
  }
  .appliedMainCard1 {
    margin-top: -20px;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    gap: 8px;
    padding: 5px 23px 10px 25px;
    place-items: center;
  }
  .AppliedDashboard {
    height: 72px;
    width: 90%;
    border-radius: 25px;
    transition: 0.5s;
    cursor: pointer;
    opacity: 0.7;
  }
  .activeAppliedDashboard {
    height: 72px;
    width: 90%;
    border-radius: 25px;
    transition: 0.2s;
    cursor: pointer;
    border: 2px solid black;
    box-shadow: 2px 2px 2px 2px rgba(3, 1, 1, 0.8);
    opacity: 1;
  }
  #heading1 {
    text-align: center;
    font-size: 11px;
  }
  #heading {
    font-size: 14px;
  }
  .Appliedseekericonsize.MuiSvgIcon-root {
    font-size: 16px;
  }
}

@media only screen and (max-width: 300px) {
  .appliedMainCard {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    gap: 8px;
    padding: 5px 23px 10px 25px;
    place-items: center;
  }
  .appliedMainCard1 {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    gap: 8px;
    padding: 5px 23px 10px 25px;
    place-items: center;
  }
  #heading1 {
    text-align: center;
    font-size: 10px;
  }
}
.scrollable-container-postjob {
  max-height: 150px;
  overflow-y: scroll;
}
/* Hide scrollbar for heights less than 180px */
@media (max-height: 180px) {
  .scrollable-container-postjob::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
  }
}
/* Show scrollbar for heights more than 180px */
@media (min-height: 181px) {
  .scrollable-container-postjob::-webkit-scrollbar {
    width: 0.4em;
    background-color: #f1f1f1;
  }
  .scrollable-container-postjob::-webkit-scrollbar-thumb {
    background-color: rgb(53, 52, 52);
  }
}
/* .appliedMainCard {
  display: grid;
  grid-template-columns: 16% 16% 16% 16% 16% 16%;
  gap: 8px;
  padding: 0px 23px 10px 25px;
  place-items: center;
} */
.squreCard {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.circle {
  position: absolute;
  transform: translate(100%, 100%);
  width: 130px;
  height: 130px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 16px;
}
@media only screen and (max-width: 640px) {
  .circle {
    position: absolute;
    transform: translate(80%, 100%);
    width: 130px;
    height: 130px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 16px;
  }
}
@media only screen and (max-width: 580px) {
  .circle {
    position: absolute;
    transform: translate(65%, 100%);
    width: 130px;
    height: 130px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 16px;
  }
}
@media only screen and (max-width: 540px) {
  .circle {
    position: absolute;
    transform: translate(50%, 100%);
    width: 130px;
    height: 130px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 16px;
  }
}
@media only screen and (max-width: 500px) {
  .circle {
    position: absolute;
    transform: translate(35%, 100%);
    width: 130px;
    height: 130px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 16px;
  }

  @media only screen and (max-width: 470px) {
    .circle {
      position: absolute;
      transform: translate(30%, 100%);
      width: 115px;
      height: 130px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 16px;
    }
  }
  @media only screen and (max-width: 445px) {
    .circle {
      position: absolute;
      transform: translate(34%, 130%);
      width: 110px;
      height: 110px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 16px;
    }
  }
}
