.bar {
  background-color: #055779;
  display: flex;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.3) !important;
}
.main {
  height: 100vh;
  background-color: #eef2f6;
  overflow: hidden;
  overflow-y: scroll;
  margin: 0;
}
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-right: 1px solid transparent;
  width: 100%;
}
.header div.MuiBox-root .image {
  width: 50%;
  /* margin-right: 42px; */
}
/* .hover:hover {
  background-color: #0675a2;
  transition: 0.3s;
  font-weight: 600;
} */

.MuiListItemButton-root.PartnerPincode {
  background-color: #1976d2;
  color: #ffffff;
  border-radius: 10px;
}

.MuiListItemButton-root.PartnerPincode:hover {
  background-color: #06519c;
  color: #ffffff;
  border-radius: 10px;
}

.hover:hover .text {
  color: #fcf6f5ff;
}

.hover {
  width: 100%;
  border-radius: 10px;
  padding: 0;
}
.active.MuiListItemButton-root {
  background-color: #0675a2;
  transition: 0.3s;
}
/* .active.MuiListItemButton-root .MuiListItemText-root {
  color: aqua;
} */
.link-active.MuiListItemButton-root .MuiListItemText-root,
.link-active.MuiListItemButton-root .MuiListItemIcon-root {
  color: #f2f2f2;
}
.link-active.MuiListItemButton-root:hover {
  background-color: #0675a2;
  transition: 0.3s;
  font-weight: 600;
}

.flex {
  flex-grow: 1;
}
.font {
  font-family: Poppins-SemiBold;
  font-size: 14px;
  font-weight: 700;
  text-transform: capitalize;
}
.sidehead {
  padding: 0px 5px;
  font-size: 12px;
  font-weight: 800;
  color: #055779;
}
.table-row.MuiTableRow-root .MuiTableCell-root {
  font-weight: bold;
  background-color: #339fcd;
  color: #ffffff;
  font-family: Poppins-Medium;
}
.tablecell.MuiTableRow-root:nth-child(odd) {
  background-color: #f2f2f2;
  color: #0a66c2;
}
.tablecell.MuiTableRow-root .MuiTableCell-root {
  font-family: Barlow-Regular;
}
.line {
  text-decoration: none;
  padding: 0 !important;
  margin: 0 !important;
}
.seeker-text {
  font-family: Poppins-SemiBold;
  display: flex;
  align-items: center;
  font-size: 1.25rem;
}
.icon-btn.MuiIconButton-root {
  color: #055779;
  /* transform: scaleX(-1); */

  transition: all 0.3s;
}
.icon-btn.MuiIconButton-root:hover {
  background-color: #055779;
  color: #ffffffff;
  transform: scale(1.2);
  /* animation: flip 0.5s linear; */
}
.icon-btn-dbox.MuiIconButton-root {
  color: #ffffffff;
  /* transform: scaleX(-1); */

  transition: all 0.3s;
}
.icon-btn-dbox.MuiIconButton-root:hover {
  background-color: #ffffffff;
  color: #055779;
  transform: scale(1.2);
  /* animation: flip 0.5s linear; */
}
.icon-btn-link.MuiIconButton-root:hover {
  color: #0a66c2;
  background-color: #f2f2f2;
  transform: scale(1.2);
}
.icon-btn-link.MuiIconButton-root {
  color: #0a66c2;
}

#main-box.MuiBox-root {
  display: flex;
  height: 100%;
}
#main-box.MuiBox-root .MuiBox-root:nth-of-type(1) {
  display: flex;
  align-items: center;
  height: 100%;
  flex-grow: 0.2;
  margin-top: auto;
}
#main-box.MuiBox-root .MuiBox-root:nth-of-type(2) {
  flex-grow: 3;
  display: flex;
  /* align-items: center; */
  justify-content: flex-start;
  height: 100%;
}
#main-box.MuiBox-root .MuiBox-root:nth-of-type(3) {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: auto;
}
.align-text {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 20px;

  /* display: flex;
  flex-direction: row;
  justify-content: space-between; */
}
span.full-details {
  font-weight: bold;
  font-family: Barlow-Bold;
}
.align-text-mentor {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 20px;

  /* display: flex;
  flex-direction: row;
  justify-content: space-between; */
}
span.full-details-mentor {
  font-weight: bold;
  font-family: Barlow-Bold;
}

.align-img {
  display: grid;
  grid-template-columns: 20% 80%;
  gap: 0px;
}
/* .d-text.MuiTypography-root div div{
  font-size: 18px;
} */

.familydetails-align {
  display: flex;
  flex-direction: column;
}
.css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
  width: 180px;
}

/* Verifier-css */

.paper-verifier.MuiPaper-root {
  padding: 20px;
  width: 100%;
  border: 1px solid rgb(238, 238, 238);
  border-radius: 24px;
}
.paper-verifier.MuiPaper-root:hover {
  padding: 20px;
  width: 100%;
  border: 1px solid rgb(238, 238, 238);
  border-radius: 24px;
  box-shadow: 0 -0.5px 10px -1.5px #dddddd;
}
.titleseeker {
  margin-top: 15px;
  font-size: larger;
}

.ImageOuterMentor {
  padding: 1em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.AdminSettingImage {
  height: 150px;
  width: 150px;
}

.AdminSettingImageDelete {
  text-align: center;
  margin-top: -5px;
}
.AdminSettingvideoDelete {
  display: flex;
  justify-content: center;
  align-items: center;
}

.AdminSettingImageBorder {
  border: 1px solid #919192;
}

.AdminSettingImageUploadContent {
  width: 100%;
  min-width: 100px;
  min-height: 100px;
  margin-top: 10px;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AdminSettingImageUploadBorder {
  border: 3px dashed #cbd5e0;
  background-color: rgba(166, 199, 212, 0.1);
  width: 50%;
  min-width: 100px;
  min-height: 100px;
  margin-top: 10px;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.AdminSettingImageContent {
  width: 100%;
  min-width: 100px;
  min-height: 100px;
  margin-top: 10px;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AdminSettingImagecard {
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(107, 108, 109, 0.7);
  width: 80%;
  transition: 0.5s;
  cursor: pointer;
  min-height: 250px;
  max-height: 350px;
}
.adminfileNameSize {
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-bottom: -7px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  border: none;
  display: inline-block;
  width: 50px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.AdminSettingVideocard {
  width: 100%;
  display: grid;
  grid-template-columns: 33% 33% 33%;
}
.AdminSettingFilecard {
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
}

.AdminSettingImagecard:hover {
  box-shadow: 0px 0px 8px 2px rgba(16, 143, 178, 0.7);
  transform: scale(1.08);
}

.adminImageDelete {
  position: absolute;
  top: 88%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 111;
  background-color: rgba(245, 245, 245, 0.6);
  box-shadow: 0px 0px 2px 2px #108fb2;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}

.socialDeleteOutlineIcon:hover {
  background-color: #0077b5;
  color: white;
}

.adminImageEdit {
  position: absolute;
  top: 8%;
  left: 90%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 111;
  background-color: rgba(245, 245, 245, 0.6);
  box-shadow: 0px 0px 2px 2px #108fb2;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}

.socialEditIcon:hover {
  background-color: #0077b5;
  color: white;
}

/* ===================== pincodepannel ===================== */

.imgblock img {
  width: 24%;
}

.text-active {
  color: #108fb2 !important;
}
.text-deactive {
  color: rgb(15, 15, 15) !important;
}

.breadcrumb-desgin,
.space-between-sor-table,
.tablecardinvestor {
  margin: 20px 0px;
}

.btn-dasboard-investor {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.investorpannelfonts-1{
  font-family: "Poppins-SemiBold";
  font-size: 14px;
  font-weight: 700;
  text-transform: capitalize;
  color: #0077b5;
}

.investorpannelfonts {
  font-family: "Poppins-SemiBold";
  font-size: 14px;
  font-weight: 700;
  text-transform: capitalize;
  color: rgb(15, 15, 15);
}

.header-inverstorpannel {
  color: #0077b5 !important;
  font-family: "Poppins-Regular" !important;
  font-size: 16px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.iconnameid {
  margin: 5px;
  padding: 0px 10px;
  border-radius: 50px;
  box-shadow: 0px 0px 4px 0px rgba(16, 143, 178, 0.2);
}
.appbarboxshadow {
  box-shadow: rgba(0, 119, 181, 0.2) 0px 2px 8px 0px !important;
}

.forms-investor-button h3 {
  font-family: "Poppins-Regular";
  font-size: 18px;
  color: #222;
  font-weight: 600;
}
.formsbtn {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.formsbtn .btn-form ,.btn-em{
  width: 150px !important;
  padding: 10px 0px !important;
  font-family: "Poppins-Regular" !important;
  background-color: #0077b5 !important;
  color: #fff !important;
  border: none !important;
  border-radius: 4px !important;
  transition: 0.5s !important;
  cursor: pointer !important;
  text-transform: capitalize !important;
}
.formsbtn .btn-form:hover ,.btn-em:hover{
  opacity: 0.6 !important;
}

.Professiona-header h3 {
  font-family: "Poppins-Regular";
  font-size: 18px;
  color: #222;
  font-weight: 600;
}
.autocomplete-inputs-for-investor {
  border: 1px solid #108fb2;
  box-shadow: 0px 0px 6px 1px rgba(16, 143, 178, 0.1) !important;
  background-color: #fff;
}
.cards-process h3 {
  font-family: "Poppins-SemiBold";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  color: #222;
}
.cards-process .cardbox-content {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}
.cards-process .cardbox-content .box-1-card {
  width: 180px;
  height: 140px;
  border-radius: 7px;
  box-shadow: 0px 0px 10px 2px rgba(216, 156, 0, 0.25);
}
.cards-process .cardbox-content .box-2-card {
  width: 180px;
  height: 140px;
  border-radius: 7px;
  box-shadow: 0px 0px 10px 1px rgba(216, 0, 65, 0.25);
}
.cards-process .cardbox-content .box-3-card {
  width: 180px;
  height: 140px;
  border-radius: 7px;
  box-shadow: 0px 0px 10px 2px rgba(0, 48, 216, 0.25);
}
.cards-process .cardbox-content .box-4-card {
  width: 180px;
  height: 140px;
  border-radius: 7px;
  box-shadow: 0px 0px 10px 2px rgba(0, 216, 177, 0.25);
}
.cards-process .cardbox-content .box-5-card {
  width: 180px;
  height: 140px;
  border-radius: 7px;
  box-shadow: 0px 0px 10px 2px rgba(0, 216, 9, 0.25);
  
}

.cards-process .cardbox-content .designpatten {
  position: relative;
  padding: 10px;
  cursor: pointer;
}

.cards-process .cardbox-content .designpatten .img-ab-card {
  width: 15px;
  position: absolute;
  top: 25%;
  right: 0%;
}

.cards-process .cardbox-content .designpatten .img-ab-card img {
  width: 100%;
}

.cards-process .cardbox-content .designpatten .icon-imgs-card {
  width: 30px;
}
.cards-process .cardbox-content .designpatten .icon-imgs-card img {
  width: 100%;
}

.contentstatus {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

.contentstatus text {
  font-family: "Poppins-Medium";
}
.contentstatus div {
  font-size: 35px;
  font-family: "Poppins-SemiBold";
  transition: 0.3s;
}

.inverstor-table-header h3 {
  font-family: "Poppins-Regular";
  font-size: 18px;
  color: #222;
  font-weight: 600;
}

.inverstor-table-header {
  margin-top: 50px;
  margin-bottom: 40px;
  text-align: center;
}
.input-search-inverstor {
  margin: 20px 0px;
}
.inputsserachtextfield {
  border: 1px solid #0077b5 !important;
  border-radius: 50px;
}
.input-search-inverstor {
  text-align: right;
}
.investortablecell {
  font-family: "Poppins-Regular" !important;
}

.investortablerow.MuiTableRow-root .MuiTableCell-root {
  font-family: "Poppins-SemiBold";
}

.btn-action-investor-1 {
  background-color: #0077b5 !important;
  color: #fff !important;
}
.btn-action-investor-2 {
  background-color: #fff !important;
  color: #0077b5 !important;
}

.deletebtn-action-investor-1{
  background-color: #fff !important;
  color: #b50000 !important;
}
.deletebtn-action-investor-1:hover{
  background-color: #b50000 !important;
  color: #fff !important;
}

.btn-action-investor-1:hover {
  background-color: #fff !important;
  color: #0077b5 !important;
}

.btn-action-investor-2:hover {
  background-color: #0077b5 !important;
  color: #fff !important;
}

.industryinvs{
  width: 65px !important;
  word-break: break-all;
}

.btn-myemployer-investor{
  margin-top: 20px;
}

.investordailog .css-1t1j96h-MuiPaper-root-MuiDialog-paper{
  border-radius: 20px;
}

.investorsdailog::-webkit-scrollbar{
  width: 5px;
}
.investorsdailog::-webkit-scrollbar-track{
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}

/* Handle */
.investorsdailog::-webkit-scrollbar-thumb {
  background:rgba(0, 119, 181,0.3); 
  border-radius: 10px;
}

/* Handle on hover */
.investorsdailog::-webkit-scrollbar-thumb:hover {
  background: #ec2172; 
}


.MuiListItemButton-root.active {
  background-color: #0675a2;
  color: #ffffff;
  border-radius: 10px;
}

.MuiListItemButton-root.link-active:hover{
  background-color: #0675a2;
  color: #ffffff;
  border-radius: 10px;
}

