.table-row.MuiTableRow-root .MuiTableCell-root {
  font-weight: bold;
  background-color: #339fcd;
  color: #ffffff;
  font-family: Poppins-Medium;
}
.tablecell.MuiTableRow-root:nth-child(odd) {
  background-color: #f2f2f2;
  color: #0a66c2;
}
.tablecell.MuiTableRow-root .MuiTableCell-root {
  font-family: Barlow-Regular;
}
.dots {
  width: 17.3px;
  height: 17.3px;
  background: #055779;
  color: #055779;
  border-radius: 50%;
  box-shadow: 28.8px 0, -28.8px 0;
  animation: dots-u8fzftlg 1.2s infinite linear alternate;
}

.wa-chat-box {
  min-width: 200px;
  min-height: 225px;
}

@keyframes dots-u8fzftlg {
  0% {
    box-shadow: 28.8px 0, -28.8px 0;
    background: 0;
  }

  33% {
    box-shadow: 28.8px 0, -28.8px 0 rgba(5, 87, 121, 0.13);
    background: rgba(5, 87, 121, 0.13);
  }

  66% {
    box-shadow: 28.8px 0 rgba(5, 87, 121, 0.13), -28.8px 0;
    background: rgba(5, 87, 121, 0.13);
  }
}
